<section>
    <div class="container-fluid">
        <div class="row h-100 w-100 m-0 login-form">
            <div class="col-4 d-none d-lg-block">
                <div class="row img-login-wrap">
                    <img src="assets/images/login/login3.png" class="p-4" style="width: 50%;" />
                    <p class="img-content">Register your school Step by Step</p>
                </div>
            </div>
            <div class="col-md-8 contents">
                <div class="row text-center m-0 p-4">
                    <div class="col-3 school-title">
                        <p class="mt-3">School Information</p>
                    </div>
                    <div class="col-3">
                    </div>
                    <div class="col-6 flex justify-content-end">
                        <span> Is your school already registered?</span>
                        <button type="button" [routerLink]="'/login'" class="app-btn outline ml-2">
                            Sign In
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form novalidate [formGroup]="schoolForm">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="title">School Details</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- group_admin chagnes-->
                                        <!--<div class="row">
                                            <div class="col-12" style="display: inline-flex;">
                                                <span class ="col-6">Do you want to manage group of schools? </span>  &nbsp;&nbsp;
                                                <div class ="col-6">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input"
                                                        formControlName="isCentralOfficer" type="radio"
                                                            name="isCentralOfficer" id="inlineRadio1"
                                                            value="Y">
                                                        <label class="form-check-label"
                                                            for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input"
                                                          formControlName="isCentralOfficer" type="radio"
                                                            name="isCentralOfficer" id="inlineRadio2"
                                                            value="N">
                                                        <label class="form-check-label"
                                                            for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="row">
                                            <div class="col-12">
                                                <span style="background-color: yellow; font-size: 12px;
                                                font-weight: 500;
                                                color: #000000;"> This setup will create central branch from which all branches can be managed</span>
                                            </div>
                                        </div>
                                        <hr /> -->

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            formControlName="instituteName" placeholder="Institute Name"
                                                            required="required" (click)="focusOnLabel('instituteName')" id="instituteName">
                                                        <label for="instituteName">Institute Name <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.instituteName"
                                                        message="Institute Name" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <select class="form-control" formControlName="instituteType"
                                                            placeholder="Institute Type" required="required">
                                                            <option value="">Select Institute Type</option>
                                                            <option *ngFor="let inst of instituteData"
                                                                [value]="inst.value">
                                                                {{inst.value}}</option>
                                                        </select>
                                                        <label for="instituteType">Institute Type <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.instituteType"
                                                        message="Institute Type" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <select class="form-control" formControlName="educationBoard"
                                                            placeholder="Board of Education" required="required">
                                                            <option value="">Select Board of Education</option>
                                                            <option *ngFor="let edu of educationBoardData"
                                                                [value]="edu.value">{{edu.value}}</option>
                                                        </select>
                                                        <label for="educationBoard">Board of Education <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.educationBoard"
                                                        message="Board of Education"
                                                        [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <select class="form-control"
                                                            formControlName="currentAcademicYear"
                                                            placeholder="Current Academic Year" required="required">
                                                            <option value="">Select Current Academic Year</option>
                                                            <option *ngFor="let yrs of academicYearData"
                                                                [value]="yrs.value">
                                                                {{yrs.value}}</option>
                                                        </select>
                                                        <!-- <input type="text" class="form-control"
                                                            formControlName="username"
                                                            placeholder="Current Academic Year" required="required"> -->
                                                        <label for="currentAcademicYear">Current Academic Year<span
                                                            class="required">*</span></label>
                                                        
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.currentAcademicYear"
                                                        message="Current Academic Year"
                                                        [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-12">
                                        <p class="title">School Contact Information</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="tel" class="form-control" formControlName="mobile"
                                                            maxlength="10"
                                                            placeholder="Mobile Number" required="required" (click)="focusOnLabel('mobile')" id="mobile">
                                                        <label for="mobile">Mobile Number <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.mobile"
                                                        message="Mobile Number" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control" formControlName="email"
                                                            placeholder="Email Address" required="required" (click)="focusOnLabel('email')" id="email">
                                                        <label for="email">Email Address <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.email"
                                                        message="Email Address" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                        </div>
                                        <div formGroupName="address">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="has-float-label">
                                                            <input type="text" class="form-control"
                                                                formControlName="addressLine1"
                                                                placeholder="Address (Building Street, Area)"
                                                                required="required" id="addressLine1">
                                                            <label for="addressLine1" (click)="focusOnLabel('addressLine1')">Address (Building Street, Area)
                                                                <span class="required">*</span></label>
                                                        </span>
                                                        <div class="app-text-danger" *ngIf="isAddress">Address is
                                                            required</div>
                                                        <!-- <validation-message
                                                            [control]="schoolForm.controls['address'].controls.addressLine1"
                                                            message="Address" [isFormSubmitted]="isFormSubmitted">
                                                        </validation-message> -->
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="has-float-label">
                                                            <input type="text" class="form-control"
                                                                formControlName="postalcode" placeholder="Postal Code"
                                                                required="required" (click)="focusOnLabel('postalcode')" id="postalcode">
                                                            <label for="postalcode">Postal Code <span
                                                                    class="required">*</span></label>
                                                        </span>
                                                        <div class="app-text-danger" *ngIf="isPostalCode">Postal Code is
                                                            required</div>
                                                        <!-- <validation-message
                                                            [control]="schoolForm.controls.address.postalcode"
                                                            message="Postal Code" [isFormSubmitted]="isFormSubmitted">
                                                        </validation-message> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="has-float-label">
                                                            <input type="text" class="form-control"
                                                                formControlName="city" placeholder="City" (click)="focusOnLabel('city')" id="city">
                                                            <label for="city">City</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="has-float-label">
                                                            <input type="text" class="form-control"
                                                                formControlName="district" placeholder="District" (click)="focusOnLabel('district')" id="district">
                                                            <label for="district">District </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <span class="has-float-label">
                                                            <input type="text" class="form-control"
                                                                formControlName="state" placeholder="State" (click)="focusOnLabel('state')" id="state">
                                                            <label for="state">State</label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-12">
                                        <p class="title">Administrator Details</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            formControlName="adminFirstName" placeholder="Name"
                                                            required="required" (click)="focusOnLabel('adminFirstName')" id="adminFirstName">
                                                        <label for="adminFirstName">First Name <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.adminFirstName"
                                                        message="First Name" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            formControlName="adminLastName" placeholder="Name"
                                                            required="required" (click)="focusOnLabel('adminLastName')" id="adminLastName">
                                                        <label for="adminLastName">Last Name <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.adminLastName"
                                                        message="Last Name" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            formControlName="adminEmail" placeholder="Email"
                                                            required="required" (click)="focusOnLabel('adminEmail')" id="adminEmail">
                                                        <label for="adminEmail">Email <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.adminEmail"
                                                        message="Email" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <span class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            formControlName="adminMobile" placeholder="Mobile Number"
                                                            required="required" (click)="focusOnLabel('adminMobile')" id="adminMobile"
                                                            maxlength="10" required="required">
                                                        <label for="adminMobile">Mobile Number <span
                                                                class="required">*</span></label>
                                                    </span>
                                                    <validation-message [control]="schoolForm.controls.adminMobile"
                                                        message="Mobile Number" [isFormSubmitted]="isFormSubmitted">
                                                    </validation-message>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <button type="button" class="app-btn mb-2" style="float: right;"
                                    (click)="save()">
                                    <i class="fa fa-step-forward mr-1" aria-hidden="true"></i>  Save & Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<swal #issueSwal (confirm)="cancel()" (confirmButtonText)="'Login'" title="" [allowOutsideClick]="false"
    text="Successfully created your institute. Please login to manage your institution." icon="success">
</swal>