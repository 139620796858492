<div class="row h-100 m-0 login-form">
    <div class="col-md-3"></div>
    <div class="col-md-6 contents">
        <div class="row justify-content-center right-panel">
            <div class="col-md-8 card form-container">
                <div style="margin-left: 2.25rem !important;">
                    <h3>Sign In</h3>
                    <p>Sign in to your account to access your Akshar Applications.
                    </p>
                </div>
                <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                <form class="form-body" novalidate [formGroup]="frmLogin">
                    <div class="row" id="resp-row" *ngIf="isLoginOtp && !isLoginOtpSent">
                        <div class="col">
                            <div class="d-inline-flex" style="width: 100%;">
                                <input type="text" style="width: 30%;" class="form-control mr-1" value="+91">
                                <input type="text" class="form-control" formControlName="username" id="mobilenumber"
                                    placeholder="Mobile Number" required="required" autofocus="autofocus">
                            </div>
                            <validation-message [control]="frmLogin.controls.username" message="Mobile Number"
                                [isFormSubmitted]="isFormSubmitted">
                            </validation-message>
                        </div>
                    </div>
                    <div class="row" id="resp-row" *ngIf="!isLoginOtp && isLoginOtpSent">
                        <div class="col">
                            <span class="has-float-label">
                                <input type="text" class="form-control" formControlName="password" placeholder="OTP"
                                    required="required" autofocus="autofocus">
                            </span>
                            <validation-message [control]="frmLogin.controls.password" message="OTP"
                                [isFormSubmitted]="isFormSubmitted">
                            </validation-message>
                        </div>
                    </div>
                    <button *ngIf="isLoginOtp && !isLoginOtpSent" type="button" class="app-btn btn-block mt-3 text-center" (click)="login()">Sign in with OTP</button>
                    <button *ngIf="!isLoginOtp && isLoginOtpSent" type="button" class="app-btn btn-block mt-3 text-center" (click)="login()">Validate OTP</button>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>