import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { BankAccount, EmpBankAccount } from "models";

@Injectable({
    providedIn: "root",
})
export class EmployeeBankAccountService extends CRUDService<EmpBankAccount> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.empBankAccountMyProfile + "/");
    }

    getBankAccount(params: any): Observable<EmpBankAccount> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.empBankAccountMyProfile}?${query}`);
    }

    updateBankAccount(data: EmpBankAccount) {
        return this.baseService.put(`${APIConstant.empBankAccountMyProfile}`, data);
    }

    saveBankAccount(data: EmpBankAccount) {
        return this.baseService.post(`${APIConstant.empBankAccountMyProfile}`, data);
    }
}
