import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    admission: `${apiPath}admission`,
    enquiriesByAssignee: `${apiPath}/admissions/enquiries/byAssignee`,
    enquiriesAssign: `${apiPath}/admissions/enquiries/assign`,
    unassigned: `${apiPath}/admissions/enquiries/unassigned`,
    enquiries: `${apiPath}/admissions/enquiries`,
    enquiryStatus: `${apiPath}/admissions/enquiries/status`,
    enquiryDate: `${apiPath}/admissions/enquiries/followup-date`,
    enquiryNotes: `${apiPath}/admissions/enquiries/notes`,
    enquiryStats: `${apiPath}/admissions/enquiries/stats`,
    enquiriesReport: `${apiPath}/admissions/enquiries/report`,
    enquiryContact: `${apiPath}/admissions/enquiries/contact`,
    searchEnquiry: `${apiPath}/admissions/enquiries/search`,
    enquiryStatsByAssignee: `${apiPath}/admissions/enquiries/stats/byassignee`,
    enquiryReport: `${apiPath}/admissions/enquiries/report`,
    admissionForm: `${apiPath}/admissions/enquiries/admissionForm`,
    enquiryForms: `${apiPath}/admissions/enquiries/settings`,
    gradeEnquiry: `${apiPath}/admissions/enquiries/byAcademicYear/getStatistics`,
    campaignAreawiseEnquiries: `${apiPath}/admissions/enquiries/enquiries/byCampaignArea`,
    statuswiseEnquiries: `${apiPath}/admissions/enquiries/enquiries/byEnquiryStatus`,
    leadEnquiries: `${apiPath}/admissions/enquiries/enquiries/byLeadSource`,
    channelwiseEnquiries: `${apiPath}/admissions/enquiries/enquiries/byChannel`,
    enquiryStatusesNeedsToFollow: `${apiPath}/admissions/enquiries/enquiryStatistics/needToFollow`,
    admissionFeeSetting: `${apiPath}/admissions/feeSettings`,
    admissionFeeBySchoolCode: `${apiPath}/admissions/feeSettings/bySchoolCode`,
    feeHeadsForPayment: `${apiPath}/finance/fee-setup/admissionFeeHead/dropdown`,
    admissionMakePayment: `${apiPath}/admissions/fees/admissionFees/process-payment`
};

export const PublicAPI = [];
