import { Injectable } from "@angular/core";
import { Timetable, TeacherSubject, EmployeeBasicInfo } from "models";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class EmployeeDashboardService {
    dataStore = {
        employeeData: null,
        leaveBalanceData: null,
        classesAndSubjects: [],
        timetableData: [],
        attendanceData: [],
        attendanceChartData: [],
    };
    private _employeeData = new BehaviorSubject<EmployeeBasicInfo>(null);
    private _leaveBalanceData = new BehaviorSubject<any>(null);
    private _classesAndSubjects = new BehaviorSubject<TeacherSubject[]>([]);
    private _timetableData = new BehaviorSubject<Timetable[]>([]);
    private _attendanceData = new BehaviorSubject<any>(null);
    private _attendanceApiCall = new BehaviorSubject<"DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY">(null);

    constructor() { }

    get employeeData() {
        return this._employeeData.asObservable();
    }

    get leaveBalanceData() {
        return this._leaveBalanceData.asObservable();
    }

    get classesAndSubjects() {
        return this._classesAndSubjects.asObservable();
    }

    get timetableData() {
        return this._timetableData.asObservable();
    }

    get attendanceData() {
        return this._attendanceData.asObservable();
    }

    get attendanceApiCall() {
        return this._attendanceApiCall.asObservable();
    }

    setEmployeeData(data: any) {
        this.dataStore.employeeData = { ...data };
        this._employeeData.next({ ...data });
    }
    setLeaveBalanceData(data: any) {
        this.dataStore.leaveBalanceData = { ...data };
        this._leaveBalanceData.next({ ...data });
    }

    setClassesAndSubjects(data: TeacherSubject[]) {
        this.dataStore.classesAndSubjects = [...data];
        this._classesAndSubjects.next([...data]);
    }

    setTimetableData(data: Timetable[]) {
        this.dataStore.timetableData = [...data];
        this._timetableData.next([...data]);
    }

    setAttendanceData(data: any) {
        this.dataStore.attendanceData = [...data.list];
        this.dataStore.attendanceChartData = [...data.chart];
        this._attendanceData.next({ ...data });
    }

    callAttendanceApi(data: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY"): any {
        this._attendanceApiCall.next(data);
    }
}
