import { AdmissionService } from "@admission-core";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { EmployeeService } from "@hr-core";
import { Images } from "@school-core";
import { Admission, EmployeeBasicInfo, LookupCategory } from "models";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DefaultDateOption, Genders, LookupService, RoleService, UploadService, ValidationService } from "projects/common/src/public-api";
import { Observable, Subject } from "rxjs";
import { PublicService } from "../public.service";

@Component({
    selector: 'app-admission',
    templateUrl: './admission.component.html',
    styleUrls: ['./admission.component.scss']
})
export class AdmissionComponent implements OnInit {
    enquiryData: Admission;
    profileForm: FormGroup;
    isFormSubmitted: boolean;
    isEditMode: boolean;
    isAdd = true;
    enquiryId: number;
    defaultImage: string = Images.user;
    image: string = Images.user;
    uploadedImage: File;
    bsConfig: Partial<BsDatepickerConfig> = {
        ...DefaultDateOption,
        customTodayClass: 'today-class'
    };
    employee$: Observable<any>;
    employeeLoading = false;
    employeeInput$ = new Subject<string>();
    isEdit: boolean;
    lookupCategory: any = LookupCategory;
    genderData: string[] = Genders;
    classroomId: number;
    courseId: number;
    isDropOut = false;
    employees: any[] = [];
    academicYears: string[] = [];
    transportationFacilities = [{
        displayName: 'Yes',
        value: 'Y'
    }, {
        displayName: 'No',
        value: 'N'
    }];
    campaignAreas: any[] = [];
    leadSources: any[] = [];
    enquiryChannels: any[] = [];
    isEditButton: boolean = true;
    isCourse = false;
    bloodGroups: string[];
    religions: string[];
    nationalities: string[];
    castes: string[];
    enquiryStatuses: string[];
    isAdmittedDate = false;
    isAdmittedSection = false;

    personalData: any = [];
    personalRow1Data: any = [];
    personalRow2Data: any = [];
    admissionData: any = [];
    admissionRow1Data: any = [];
    admissionRow2Data: any = [];
    otherData: any = [];
    otherRow1Data: any = [];
    otherRow2Data: any = [];
    set1 = [1, 2, 3, 4, 5];
    primaryRowData: any = [];
    secondaryRowData: any = [];

    ngOnInit(): void {
        this.loadEmployee();
        this.getAcademicData();
        this.getEnquiryStatuses()
    }

    constructor(
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private admissionService: AdmissionService,
        private lookupService: LookupService,
        private publicService: PublicService,
        private uploadService: UploadService,
        private employeeService: EmployeeService,
        private roleService: RoleService,
        private toastr: ToastrService) {
        this.createForm();
        this.getEnquiryFormSetting();
        this.getContactEnquiryFormSetting();
    }

    private createForm() {
        this.profileForm = this.formBuilder.group({
            studentProfileId: [null],
            studentFirstName: ["", [Validators.required]],
            studentLastName: ["", [Validators.required]],
            dateOfBirth: ["", [Validators.required]],
            motherTongue: [""],
            bloodGroup: [null],
            aadharNbr: [""],
            gender: ["", [Validators.required]],
            religion: [null],
            nationality: [null],
            caste: [null],
            subCaste: [null],
            parentAnualIncome: [""],
            enquiryDate: [new Date(), [Validators.required]],
            enquiryStatus: ["New", [Validators.required]],
            nextFollowUpDate: [""],
            campaignArea: [null],
            leadSource: [null],
            channel: [null],
            referredBy: [""],
            academicYear: [null, [Validators.required]],
            transportationFacility: [null],
            boardingType: [null],
            admittedDate: [""],
            previousSchool: [""],
            dropDate: [""],
            dropOutReason: [""],
            assignedTo: [null],
            admittedClass: [""],
            contactDTO: this.formBuilder.group({
                primaryName: ['', [Validators.required]],
                primaryMobile: ['', [Validators.required, , ValidationService.phoneNoValidator]],
                primaryEmail: [''],
                primaryOccupation: [''],
                primaryRelation: [''],
                secondaryName: [''],
                secondaryMobile: ['', [ValidationService.phoneNoValidator]],
                secondaryEmail: [''],
                secondaryOccupation: [''],
                secondaryRelation: [''],
                addressLine1: [''],
                addressLine2: [''],
                city: [''],
                state: [''],
                district: [''],
                postalCd: [''],
                country: ['']
            })
        });
    }

    getEnquiryFormSetting() {
        this.personalRow1Data = [];
        this.personalRow2Data = [];
        this.admissionRow1Data = [];
        this.admissionRow2Data = [];
        this.spinner.show();
        this.admissionService.getEnquiryFormSettings().subscribe((result) => {
            this.spinner.hide();
            if (result) {
                if (result && result.length > 0) {
                    result.forEach(item => {
                        if (item.attributeName === 'customizedField1'
                            || item.attributeName === 'customizedField2'
                            || item.attributeName === 'customizedField3'
                            || item.attributeName === 'customizedField4'
                            || item.attributeName === 'customizedField5') {

                            var numb = item.attributeName.match(/\d/g);
                            item['rowIndex'] = parseInt(numb[0]);
                            this.personalData.push(item);
                            this.personalData = this.personalData.sort((a, b) => a.displayOrder - b.displayOrder);
                            this.profileForm.addControl(item.attributeName, new FormControl(''));
                            if (item.isRequired === 'Y') {
                                this.profileForm.get(item.attributeName).setValidators(Validators.required);
                                this.profileForm.updateValueAndValidity();
                            }
                        }
                        if (item.attributeName === 'customizedField6'
                            || item.attributeName === 'customizedField7'
                            || item.attributeName === 'customizedField8'
                            || item.attributeName === 'customizedField9'
                            || item.attributeName === 'customizedField10') {
                            var numb = item.attributeName.match(/\d/g);
                            item['rowIndex'] = parseInt(numb[0]);
                            this.admissionData.push(item);
                            this.admissionData = this.admissionData.sort((a, b) => a.displayOrder - b.displayOrder);
                            this.profileForm.addControl(item.attributeName, new FormControl(''));
                            if (item.isRequired === 'Y') {
                                this.profileForm.get(item.attributeName).setValidators(Validators.required);
                                this.profileForm.updateValueAndValidity();
                            }
                        }
                        if (item.attributeName === 'customizedField11'
                            || item.attributeName === 'customizedField12'
                            || item.attributeName === 'customizedField13'
                            || item.attributeName === 'customizedField14'
                            || item.attributeName === 'customizedField15') {
                            var numb = item.attributeName.match(/\d/g);
                            item['rowIndex'] = parseInt(numb[0]);
                            this.otherData.push(item);
                            this.otherData = this.otherData.sort((a, b) => a.displayOrder - b.displayOrder);
                            this.profileForm.addControl(item.attributeName, new FormControl(''));
                            if (item.isRequired === 'Y') {
                                this.profileForm.get(item.attributeName).setValidators(Validators.required);
                                this.profileForm.updateValueAndValidity();
                            }
                        }
                    });
                    // this.personalData = this.personalData.sort((a, b) => a.displayOrder > b.displayOrder);
                    // this.admissionData = this.admissionData.sort((a, b) => a.displayOrder > b.displayOrder);
                    // this.otherData = this.otherData.sort((a, b) => a.displayOrder > b.displayOrder);
                    if (this.personalData && this.personalData.length > 0) {
                        for (let [index, item] of this.personalData.entries()) {
                            if (this.personalData.length > 3) {
                                if (index < this.personalData.length / 2) {
                                    this.personalRow1Data.push(item);
                                }
                                if (index >= this.personalData.length / 2) {
                                    this.personalRow2Data.push(item);
                                }
                            } else {
                                this.personalRow1Data.push(item);
                            }
                        }
                    }
                    if (this.admissionData && this.admissionData.length > 0) {
                        for (let [index, item] of this.admissionData.entries()) {
                            if (this.admissionData.length > 3) {
                                if (index < this.admissionData.length / 2) {
                                    console.log('row 1', item)
                                    this.admissionRow1Data.push(item);
                                }
                                if (index >= this.admissionData.length / 2) {
                                    console.log('row 2', item)
                                    this.admissionRow2Data.push(item);
                                }
                            } else {
                                this.admissionRow1Data.push(item);
                            }
                        }
                    }
                    if (this.otherData && this.otherData.length > 0) {
                        for (let [index, item] of this.otherData.entries()) {
                            if (this.otherData.length > 3) {
                                if (index < this.otherData.length / 2) {
                                    this.otherRow1Data.push(item);
                                }
                                if (index >= this.otherData.length / 2) {
                                    this.otherRow2Data.push(item);
                                }
                            } else {
                                this.otherRow1Data.push(item);
                            }
                        }
                    }
                }
            }
        }, (error) => {
            console.log(error);
            this.spinner.hide();
        });
    }

    private getEnquiryStatuses() {
        this.lookupService.lookup(LookupCategory.ENQUIRY_STATUS).subscribe((res) => {
            this.enquiryStatuses = res;
        });
    }

    get admittedClass() {
        return this.profileForm.get('admittedClass') as FormGroup;
    }

    getImage() {
        const params = {
            module: 'Enquiries',
            fileName: this.enquiryId,
            // contentType: 'image/jpeg'
        }
        this.uploadService.getDocument(params).subscribe((data) => {
            if (data.status != 406) {
                this.image = data ? data.URL : Images.user;
            } else {
                this.image = Images.user;
            }
        }, err => {
            this.image = this.defaultImage;
        });
    }

    sectionChangeEvent(item: number) {
        this.classroomId = item;
    }

    employeeTrackByFn(item: EmployeeBasicInfo) {
        return item?.employeeProfileId;
    }

    enquiryStatusChange(e) {
        if (e == 'Dropped') {
            this.isDropOut = true;
            this.isAdmittedDate = false;
            this.isAdmittedSection = false;
        } else {
            if (e == 'Admitted') {
                if (this.profileForm.value['admittedDate'] == null || this.profileForm.value['admittedDate'] == '') {
                    this.isAdmittedDate = true;
                    return false;
                } else {
                    this.isAdmittedDate = false;
                }
                if (this.classroomId == null) {
                    this.isAdmittedSection = true;
                    return false;
                } else {
                    this.isAdmittedSection = false;
                }
            } else {
                this.isAdmittedDate = false;
                this.isAdmittedSection = false;
            }
            this.isDropOut = false;
            this.profileForm.get('dropDate').setValue('');
            this.profileForm.get('dropOutReason').setValue('');
        }
    }

    private loadEmployee() {
        this.spinner.show();
        this.employeeService.getAllEmployees().subscribe((data: any[]) => {
            this.employees = data;
            this.spinner.hide();
        },
            error => {
                this.employees = [];
                this.spinner.hide();
            });
    }

    private getAcademicData() {
        const params = {
            includeFutureYears: true
        };
        this.lookupService.getAcademicYearsWithParam(params).subscribe(
            (result) => {
                this.academicYears = result;
            },
            (error) => {
                this.academicYears = [];
            }
        );
    }

    securityRights(group: string) {
        return this.roleService.securityRights(group);
    }

    get contactDTO() {
        return this.profileForm.get("contactDTO") as FormGroup;
    }

    getContactEnquiryFormSetting() {
        this.primaryRowData = [];
        this.secondaryRowData = [];
        this.spinner.show();
        this.admissionService.getEnquiryFormSettings().subscribe((result) => {
            this.spinner.hide();
            if (result) {
                if (result && result.length > 0) {
                    result.forEach(item => {
                        if (item.attributeName === 'customizedField16'
                            || item.attributeName === 'customizedField17'
                            || item.attributeName === 'customizedField18'
                            || item.attributeName === 'customizedField19'
                            || item.attributeName === 'customizedField20') {

                            var numb = item.attributeName.match(/\d/g);
                            item['rowIndex'] = parseInt(numb[0]);
                            this.primaryRowData.push(item);
                            this.primaryRowData = this.primaryRowData.sort((a, b) => a.displayOrder - b.displayOrder);
                            this.contactDTO.addControl(item.attributeName, new FormControl(''));
                            if (item.isRequired === 'Y') {
                                this.contactDTO.get(item.attributeName).setValidators(Validators.required);
                                this.contactDTO.updateValueAndValidity();
                            }
                        }
                        if (item.attributeName === 'customizedField21'
                            || item.attributeName === 'customizedField22'
                            || item.attributeName === 'customizedField23'
                            || item.attributeName === 'customizedField24'
                            || item.attributeName === 'customizedField25') {
                            var numb = item.attributeName.match(/\d/g);
                            item['rowIndex'] = parseInt(numb[0]);
                            this.secondaryRowData.push(item);
                            this.secondaryRowData = this.secondaryRowData.sort((a, b) => a.displayOrder - b.displayOrder);
                            this.contactDTO.addControl(item.attributeName, new FormControl(''));
                            if (item.isRequired === 'Y') {
                                this.contactDTO.get(item.attributeName).setValidators(Validators.required);
                                this.contactDTO.updateValueAndValidity();
                            }
                        }
                    });
                    // this.primaryRowData = this.primaryRowData.sort((a, b) => a.displayOrder > b.displayOrder);
                    // this.secondaryRowData = this.secondaryRowData.sort((a, b) => a.displayOrder > b.displayOrder);
                }
            }
        }, (error) => {
            console.log(error);
            this.spinner.hide();
        });
    }

    save() {
        this.isFormSubmitted = true;
        if (this.profileForm.value['admittedClass'] == null || this.profileForm.value['admittedClass'] == '') {
            this.isCourse = true;
            return false;
        } else {
            this.profileForm.value['admittedClass'] = this.profileForm.value['admittedClass'];
            this.isCourse = false;
        }
        if (this.profileForm.invalid) {
            return;
        }
        if (this.profileForm.value['enquiryStatus'] != null && this.profileForm.value['enquiryStatus'] != '' && this.profileForm.value['enquiryStatus'] == 'Admitted') {
            if (this.profileForm.value['admittedDate'] == null || this.profileForm.value['admittedDate'] == '') {
                this.isAdmittedDate = true;
                return false;
            } else {
                this.isAdmittedDate = false;
            }
            if (this.classroomId == null) {
                this.isAdmittedSection = true;
                return false;
            } else {
                this.isAdmittedSection = false;
            }
        }
        if (this.profileForm.value['enquiryStatus'] != null && this.profileForm.value['enquiryStatus'] != '' && this.profileForm.value['enquiryStatus'] == 'Admitted') {
            if (this.classroomId == null) {
                this.toastr.error('Please select admitted section');
                return false;
            }
        }
        this.spinner.show();
        const formData = this.profileForm.value;
        const enquiry: Admission = Object.assign(this.enquiryData || {}, formData);
        enquiry.classroomId = this.classroomId;
        this.publicService.submitAdmission(enquiry).subscribe(x => {
            this.enquiryId = x.enquiryId;
            this.spinner.hide();
            this.upload();
            this.toastr.success('Enquiry created successfully');
            setTimeout(() => {
                window.location.reload();
            }, 700);
        }, err => {
            this.spinner.hide();
        });
    }

    private upload() {
        if (this.uploadedImage) {
            this.uploadService.uploadDocument('Enquiries', this.enquiryId, '', this.uploadedImage).subscribe(() => {

            })
        } else {

        }
    }

    uploadedFile(file: File) {
        this.uploadedImage = file;
    }
}