<section>
    <div class="app-box mb-5 p-3">
        <div class="row title-row">
            <div class="col">
                <span class="title">Enquiry Form Setting</span>
            </div>
            <div class="d-inline-flex">
                <button type="button" (click)="save()" class="app-btn"><i class="fa fa-floppy-o"
                        aria-hidden="true"></i>&nbsp;Save</button>
            </div>
        </div>
        <div class="cw-wizard-content" id="customer-space">
            <form class="ciright-form mt-4 chek-info" [formGroup]="profileForm">
                <div class="row mt-2 mb-1">
                    <div class="col-12">
                        <p class="title">Personal Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col py-2 text-center">
                        <app-image-upload [imageUrl]="image"
                            (uploadedFile)="uploadedFile($event)">
                        </app-image-upload>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-4">
                        <label for="studentFirstName">First Name<span class="required">*</span></label>
                        <input id="studentFirstName" type="text" class="form-control"
                            formControlName="studentFirstName">
                        <validation-message [control]="profileForm.controls.studentFirstName" message="First Name"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label for="studentLastName">Last Name<span class="required">*</span></label>
                        <input id="studentLastName" type="text" class="form-control" formControlName="studentLastName">
                        <validation-message [control]="profileForm.controls.studentLastName" message="Last Name"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Gender<span class="required">*</span></label>
                        <div>
                            <div *ngFor="let item of genderData"
                                class="custom-control custom-radio custom-control-inline">
                                <input type="radio" [id]="'radio' + item" formControlName="gender"
                                    class="custom-control-input" [value]="item" />
                                <label class="custom-control-label" [for]="'radio' + item">{{ item
                                    }}</label>
                            </div>
                            <validation-message [control]="profileForm.controls.gender" message="Gender"
                                [isFormSubmitted]="isFormSubmitted">
                            </validation-message>
                        </div>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-4" id="resp-col">
                        <label for="aadharNbr">Aadhar Number</label>
                        <input id="aadharNbr" type="text" class="form-control" formControlName="aadharNbr">
                    </div>
                    <div class="col-md-4 date-field" id="resp-col">
                        <label>Date Of Birth<span class="required">*</span></label>
                        <div class="position-relative">
                            <input type="text" class="form-control" formControlName="dateOfBirth"
                                aria-describedby="Date Of Birth" [bsConfig]="bsConfig" bsDatepicker
                                #dp1="bsDatepicker" />
                            <div class="icon-box-dob">
                                <i class="fa fa-calendar-o" (click)="dp1.toggle()" aria-hidden="true"></i>
                            </div>
                        </div>
                        <validation-message [control]="profileForm.controls.dateOfBirth" message="Date Of Birth"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Blood Group</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="bloodGroup"
                            [group]="profileForm" displayName="Blood Group" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.BLOOD_GROUP" [allowAdd]="true">
                        </app-select-box>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-4" id="resp-col" *ngFor="let personal of personalRow1Data; let i = index;">
                        <label>{{personal.labelName}}<span *ngIf="personal.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{personal.attributeName}}" />
                        <validation-message *ngIf="personal.isRequired === 'Y'"
                            [control]="profileForm.controls[personal.attributeName]" message="{{personal.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-4" id="resp-col" *ngFor="let personal of personalRow2Data; let i = index;">
                        <label>{{personal.labelName}}<span *ngIf="personal.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{personal.attributeName}}" />
                        <validation-message *ngIf="personal.isRequired === 'Y'"
                            [control]="profileForm.controls[personal.attributeName]" message="{{personal.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Enquiry Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3 date-field" id="resp-col">
                        <label>Enquiry Date<span class="required">*</span></label>
                        <div class="position-relative">
                            <input type="text" class="form-control" formControlName="enquiryDate"
                                aria-describedby="Enquiry Date" [bsConfig]="bsConfig" #dp="bsDatepicker" bsDatepicker />
                            <div class="icon-box-dob">
                                <i class="fa fa-calendar-o" (click)="dp.toggle()" aria-hidden="true"></i>
                            </div>
                        </div>
                        <validation-message [control]="profileForm.controls.enquiryDate" message="Enquiry Date"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Enquiry Status<span class="required">*</span></label>
                        <ng-select [items]="enquiryStatuses" formControlName="enquiryStatus" bindLabel="value"
                            bindValue="value" placeholder="Select Enquiry Status">
                        </ng-select>
                        <validation-message [control]="profileForm.controls.enquiryStatus" message="Enquiry Status"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Assign To</label>
                        <ng-select [items]="employees" bindLabel="fullName" formControlName="assignedTo"
                            placeholder="Select Assigned To">
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item?.employeeProfileId">
                                    {{ item.fullName }} ({{ item.employeeId }})
                                </span>
                                <span *ngIf="!item?.employeeProfileId">Select Assigned To</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <label *ngIf="item?.employeeProfileId">
                                    <b>{{ item.fullName }} ({{ item.employeeId }})</b>
                                </label>
                            </ng-template>
                        </ng-select>
                        <validation-message [control]="profileForm.controls.assignedTo" message="Employee"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                        <!-- <p>
                            {{ enquiryData?.assignedTo?.fullName }} ({{
                            enquiryData?.assignedTo?.employeeId
                            }})
                        </p> -->
                    </div>
                    <div class="col-md-3 date-field" id="resp-col">
                        <label>Next Followup Date</label>
                        <div class="position-relative">
                            <input type="text" class="form-control" formControlName="nextFollowUpDate"
                                aria-describedby="Next Followup Date" [bsConfig]="bsConfig" bsDatepicker />
                            <div class="icon-box-dob">
                                <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3 date-field" id="resp-col" *ngIf="isDropOut">
                        <label>Drop Date</label>
                        <input type="text" class="form-control" formControlName="dropDate" aria-describedby="Drop Date"
                            [bsConfig]="bsConfig" bsDatepicker />
                        <div class="icon-box-dob">
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-3" id="resp-col" *ngIf="isDropOut">
                        <label>Drop Out Reason</label>
                        <input type="text" class="form-control" formControlName="dropOutReason"
                            aria-describedby="Drop Reason" />
                    </div>
                </div>
                <div class="row mt-2 mb-4">
                    <div class="col-12" style="display: flex;">
                        <p class="title mr-3">Admission Information</p> <span style="font-size: 14px;">Admitted
                            Section
                            and Admitted Date are required only when enquiry is converted to admission.</span>
                        <!-- <p class="title">Admission Information</p> -->
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col">
                        <label>Academic Year<span class="required">*</span></label>
                        <ng-select [items]="academicYears" formControlName="academicYear"
                            placeholder="Select Academic Year">
                        </ng-select>
                        <validation-message [control]="profileForm.controls.academicYear" message="Academic Year"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-3 date-field" id="resp-col">
                        <label>Admitted Date</label>
                        <input type="text" class="form-control" formControlName="admittedDate"
                            aria-describedby="Admitted Date" #dp1="bsDatepicker" [bsConfig]="bsConfig" bsDatepicker />
                        <div class="icon-box-dob">
                            <i class="fa fa-calendar-o" (click)="dp1.toggle()" aria-hidden="true"></i>
                        </div>
                        <div class="app-text-danger" *ngIf="isAdmittedDate">*Admitted Date is required</div>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Class of Admission<span class="required">*</span></label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="admittedClass"
                            [group]="profileForm" displayName="Class of Admission" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.CLASS_OF_ADMISSION" [allowAdd]="true">
                        </app-select-box>
                        <!-- <app-select-course [ngClass]="{'disabled-div' : isEditButton}" [(value)]="courseId" controlName="courseId"
                            (valueChange)="courseChangeEvent($event)">
                        </app-select-course> -->
                        <div class="app-text-danger" *ngIf="isCourse">*Class of Admission is required</div>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Admitted Section</label>
                        <app-single-classroom [(value)]="classroomId" (valueChange)="sectionChangeEvent($event)">
                        </app-single-classroom>
                        <div class="app-text-danger" *ngIf="isAdmittedSection">*Admitted Section is required
                        </div>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col">
                        <label>Boarding Type</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="boardingType"
                            [group]="profileForm" [isFormSubmitted]="isFormSubmitted" displayName="Boarding Type"
                            [categoryName]="lookupCategory.BoardingType" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Previous School</label>
                        <input type="text" class="form-control" formControlName="previousSchool"
                            aria-describedby="Previous School" />
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" *ngFor="let admission of admissionRow1Data; let i = index;">
                        <label>{{admission.labelName}}<span *ngIf="admission.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{admission.attributeName}}" />
                        <validation-message *ngIf="admission.isRequired === 'Y'"
                            [control]="profileForm.controls[admission.attributeName]" message="{{admission.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col" *ngFor="let admission of admissionRow2Data; let i = index;">
                        <label>{{admission.labelName}}<span *ngIf="admission.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{admission.attributeName}}" />
                        <validation-message *ngIf="admission.isRequired === 'Y'"
                            [control]="profileForm.controls[admission.attributeName]" message="{{admission.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Lead Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col">
                        <label>Campaign Area</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="campaignArea"
                            [group]="profileForm" displayName="Campaign Area" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.CAMPAIGN_AREA" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Lead Source</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="leadSource"
                            [group]="profileForm" displayName="Lead Source" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.LEAD_SOURCE" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Channel</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="channel" [group]="profileForm"
                            displayName="Channel" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.ENQUIRY_CHANNEL" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Referred By</label>
                        <input type="text" class="form-control" formControlName="referredBy"
                            aria-describedby="Referred By" placeholder="Enter Referred By" />
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Other Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col">
                        <label>Nationality</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="nationality"
                            [group]="profileForm" displayName="Nationality" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.NATIONALITY" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Religion</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="religion" [group]="profileForm"
                            displayName="Religion" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.RELIGION" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Caste</label>
                        <app-select-box module="ADMISSIONS" customFields="" controlName="caste" [group]="profileForm"
                            displayName="Caste" [isFormSubmitted]="isFormSubmitted"
                            [categoryName]="lookupCategory.CASTE" [allowAdd]="true">
                        </app-select-box>
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Sub Caste</label>
                        <input type="text" class="form-control" formControlName="subCaste"
                            aria-describedby="Sub Caste" />
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col">
                        <label>Mother Tongue</label>
                        <input type="text" class="form-control" formControlName="motherTongue"
                            aria-describedby="Mother Tongue" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Parent Annual Income</label>
                        <input type="text" class="form-control" formControlName="parentAnualIncome"
                            aria-describedby="Parent Annual Income" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Transportation Facility</label>
                        <ng-select bindLabel="displayName" bindValue="value" [items]="transportationFacilities"
                            formControlName="transportationFacility" placeholder="Select Transportation Facility">
                        </ng-select>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col" *ngFor="let other of otherRow1Data; let i = index;">
                        <label>{{other.labelName}}<span *ngIf="other.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{other.attributeName}}" />
                        <validation-message *ngIf="other.isRequired === 'Y'" message="{{other.labelName}}"
                            [control]="profileForm.controls[other.attributeName]" [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <div class="row" id="resp-row">
                    <div class="col-md-3" id="resp-col" *ngFor="let other of otherRow2Data; let i = index;">
                        <label>{{other.labelName}}<span *ngIf="other.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{other.attributeName}}" />
                        <validation-message *ngIf="other.isRequired === 'Y'" message="{{other.labelName}}"
                            [control]="profileForm.controls[other.attributeName]" [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Primary Contact Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col">
                        <label>Full Name <span class="required"> *</span></label>
                        <input type="text" class="form-control" formControlName="primaryName"
                            aria-describedby="Full Name" />
                        <validation-message [control]="profileForm.controls?.contactDTO?.controls?.primaryName" message="Full Name"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Mobile Number <span class="required"> *</span></label>
                        <input type="tel" class="form-control" formControlName="primaryMobile"
                            aria-describedby="Mobile Number" />
                        <validation-message [control]="profileForm.controls?.contactDTO?.controls?.primaryMobile" message="Mobile Number"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Email </label>
                        <input type="text" class="form-control" formControlName="primaryEmail"
                            aria-describedby="Email" />
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col">
                        <label>Occupation </label>
                        <input type="text" class="form-control" formControlName="primaryOccupation"
                            aria-describedby="Occupation" />
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Relation</label>
                        <input type="text" class="form-control" formControlName="primaryRelation"
                            aria-describedby="Relation" />
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col" *ngFor="let primary of primaryRowData; let i = index;">
                        <label>{{primary.labelName}}<span *ngIf="primary.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{primary.attributeName}}" />
                        <validation-message *ngIf="primary.isRequired === 'Y'"
                            [control]="profileForm.controls?.contactDTO?.controls[primary.attributeName]" message="{{primary.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Secondary Contact Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col">
                        <label>Full Name </label>
                        <input type="text" class="form-control" formControlName="secondaryName"
                            aria-describedby="Full Name" />
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Mobile Number </label>
                        <input type="tel" class="form-control" formControlName="secondaryMobile"
                            aria-describedby="Mobile Number" />
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Email </label>
                        <input type="text" class="form-control" formControlName="secondaryEmail"
                            aria-describedby="Email" />
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col">
                        <label>Occupation </label>
                        <input type="text" class="form-control" formControlName="secondaryOccupation"
                            aria-describedby="Occupation" />
                    </div>
                    <div class="col-md-4" id="resp-col">
                        <label>Relation </label>
                        <input type="text" class="form-control" formControlName="secondaryRelation"
                            aria-describedby="Relation" />
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-4" id="resp-col" *ngFor="let secondary of secondaryRowData; let i = index;">
                        <label>{{secondary.labelName}}<span *ngIf="secondary.isRequired === 'Y'"
                                class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="{{secondary.attributeName}}" />
                        <validation-message *ngIf="secondary.isRequired === 'Y'"
                            [control]="profileForm.controls?.contactDTO?.controls[secondary.attributeName]" message="{{secondary.labelName}}"
                            [isFormSubmitted]="isFormSubmitted">
                        </validation-message>
                    </div>
                </div>
                <hr />
                <div class="row mt-2 mb-4">
                    <div class="col-12">
                        <p class="title">Contact Address Information</p>
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-3" id="resp-col">
                        <label>Street Address 1</label>
                        <input type="text" class="form-control" formControlName="addressLine1"
                            aria-describedby="Street Address 1" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Street Address 2</label>
                        <input type="text" class="form-control" formControlName="addressLine2"
                            aria-describedby="Street Address 2" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>City</label>
                        <input type="text" class="form-control" formControlName="city" aria-describedby="City" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>District</label>
                        <input type="text" class="form-control" formControlName="district"
                            aria-describedby="District" />
                    </div>
                </div>
                <div class="row" id="resp-row" formGroupName="contactDTO">
                    <div class="col-md-3" id="resp-col">
                        <label>State</label>
                        <input type="text" class="form-control" formControlName="state" aria-describedby="State" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Postal Code</label>
                        <input type="text" class="form-control" formControlName="postalCd"
                            aria-describedby="Postal Code" />
                    </div>
                    <div class="col-md-3" id="resp-col">
                        <label>Country</label>
                        <input type="text" class="form-control" formControlName="country" aria-describedby="Country" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<swal #deleteSwal title="Are you sure?" text="Once deleted, you will not be able to recover this enquiry!"
    icon="warning" [showCancelButton]="true" [focusCancel]="true" confirmButtonColor="#3085d6" cancelButtonColor="#d33"
    confirmButtonText="Yes, delete it!" cancelButtonText="No, cancel pls!" [allowOutsideClick]="false">
</swal>