<!-- <div class="row h-100 m-0">
    <div class="col-5 d-none d-lg-block p-0 img-login-wrap">
        <img src="assets/images/login_bg.png" class="img-login" />
    </div>
    <div class="col p-0 login-bg">
        <div class="row text-center m-0 p-4">
            <div class="col-5"></div>
            <div class="col-7 flex justify-content-end">
               <span> You haven't yet registered your school?</span>
                <button type="button" class="app-btn outline ml-2">
                    Register
                </button>
            </div>
            <div class="col-12 my-5">
                <a href="javascript:void(0);" class="mb-2"><img src="assets/images/logo-dark.png" alt="Icon" /></a>
            </div>
            <div class="col-12 mb-4">
                <h4 class="display-6">Sign In</h4>
                <p>Sign in to your account to access your Akshar Applications.</p>
            </div>
            <div class="col-12">
                <form class="app-box form-body py-5 px-4 mx-4" novalidate [formGroup]="frmLogin" (submit)="login()">
                    <div class="form-group mb-4">
                        <label class="label-box" for="username">Username</label>
                        <input type="text" formControlName="username" class="form-control" />
                        <div class="left-icon-box">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div class="right-icon-box-username">
                            <i class="fa fa-check-circle"
                                [ngStyle]="{ display: username.valid && username.touched ? 'block' : 'none' }"></i>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <label class="label-box" for="password">Password</label>
                        <input [type]="hide ? 'text' : 'password'" formControlName="password" class="form-control" />
                        <div class="right-icon-box">
                            <a href=""><i class="fa" [ngClass]="{ 'fa-eye-slash': !hide, 'fa-eye': hide }"
                                    (click)="hide = !hide" [attr.aria-pressed]="hide"></i></a>
                        </div>
                        <div class="left-icon-box">
                            <i class="fa fa-key" aria-hidden="true"></i>
                        </div>
                    </div>
                    <p *ngIf="error" class="text-left text-danger">{{ error }}</p>
                    <div class="d-flex justify-content-between align-items-center mt-5">
                        <a href="javascript:void(0);" class="text-dark">Forgot your Password?</a>
                        <button type="submit" [disabled]="!frmLogin.valid" class="app-btn">
                            Submit <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->

<div class="row h-100 w-100 m-0 login-form" *ngIf="!isLoginOtp && !isLoginOtpSent && !isAppList">
    <div class="col-5 d-none d-lg-block">
        <div class="row img-login-wrap">
            <img src="assets/images/login/login1.png" class="img-login" />
        </div>
    </div>
    <div class="col-md-7 contents">
        <div class="row text-center m-0 p-4">
            <div class="col-4"></div>
            <div class="col-8 flex justify-content-end">
                <span> You haven't yet registered your school?</span>
                <button type="button" class="app-btn outline ml-2" (click)="backToLogin()">
                    Register
                </button>
            </div>
        </div>
        <div class="row justify-content-center right-panel">
            <div class="col-md-8">
                <div class="mb-4" style="margin-left: 2.25rem !important;" *ngIf="isLogin">
                    <h3>Sign In</h3>
                    <p class="mb-4">Sign in to your account to access your Akshar Applications.
                    </p>
                </div>
                <div class="mb-4" style="margin-left: 2.25rem !important;" *ngIf="!isLogin">
                    <h3>Register your Institute now</h3>
                    <p class="mb-4">You are just one step away to onboard your school.
                    </p>
                </div>
                <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                <form class="form-body" novalidate [formGroup]="frmLogin" (keyup.enter)="login()">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <span class="has-float-label">
                                    <input type="text" class="form-control" formControlName="username"
                                        placeholder="Username" required="required" id="username">
                                    <label for="username" (click)="focusOnLabel('username')">Username <span class="required">*</span></label>
                                </span>
                                <validation-message [control]="frmLogin.controls.username" message="Username"
                                    [isFormSubmitted]="isFormSubmitted">
                                </validation-message>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group input-group last mb-4">
                                <span class="has-float-label">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="password" placeholder="Password" id="password">
                                    <label for="password" (click)="focusOnLabel('password')">Password <span class="required">*</span></label>
                                </span>
                                <div class="input-group-append">
                                    <span class="input-group-text" (click)="toggleFieldTextType()">
                                        <i class="fa" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }"></i>
                                    </span>
                                </div>
                            </div>
                            <validation-message [control]="frmLogin.controls.password" message="Password"
                                [isFormSubmitted]="isFormSubmitted">
                            </validation-message>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center">
                        <span><a href="#" [routerLink]="'/forgot-password'" class="forgot-pass">Forgot Password</a></span>
                    </div>

                    <button type="button" class="app-btn btn-block" (click)="login()">Sign in with Username</button>
                    <span class="d-block text-center my-4 text-muted">&mdash; OR &mdash;</span>
                    <button type="button" class="app-btn btn-block outline" (click)="resetUserNamePwd();onSignInOTP()">Sign in with
                        OTP</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row h-100 m-0 login-form" *ngIf="isLoginOtp && !isLoginOtpSent && !isAppList">
    <div class="col-5 d-none d-lg-block">
        <div class="row img-login-wrap">
            <img src="assets/images/login/login1.png" class="img-login" />
        </div>
    </div>
    <div class="col-md-7 contents">
        <div class="row text-center m-0 p-4">
            <div class="col-4"></div>
            <div class="col-8 flex justify-content-end">
                <span> You haven't yet registered your school?</span>
                <button type="button" class="app-btn outline ml-2" (click)="backToLogin()">
                    Register
                </button>
            </div>
        </div>
        <div class="row justify-content-center right-panel">
            <div class="col-md-8">
                <div class="mb-4" style="margin-left: 2.25rem !important;" *ngIf="isLogin">
                    <h3>Sign In</h3>
                    <p class="mb-4">Sign in to your account to access your Akshar Applications.
                    </p>
                </div>
                <div class="mb-4" style="margin-left: 2.25rem !important;" *ngIf="!isLogin">
                    <h3>Register your Institute now</h3>
                    <p class="mb-4">You are just one step away to onboard your school.
                    </p>
                </div>
                <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                <form class="form-body" novalidate [formGroup]="frmLogin" (keyup.enter)="login()">
                    <div class="form-group" style="display: flex;">
                        <input type="text" class="form-control mr-1" style="width: 14%;" value="+91">
                        <span class="has-float-label" style="width: 86%;">
                            <input type="text" class="form-control" formControlName="username"
                                id="mobilenumber"
                                placeholder="Mobile Number" required="required" autofocus="autofocus">
                            <label for="username" (click)="focusOnLabel('mobilenumber')">Mobile Number <span class="required">*</span></label>
                        </span>
                    </div>
                    <validation-message [control]="frmLogin.controls.username" message="Mobile Number"
                        [isFormSubmitted]="isFormSubmitted">
                    </validation-message>
                    <!-- <div class="d-flex mb-4 align-items-center">
                        <span><a href="#" class="forgot-pass">Forgot Password</a></span>
                    </div> -->
                    <button type="button" class="app-btn btn-block" (click)="login()">Sign in with OTP</button>
                    <span class="d-block text-center my-4 text-muted">&mdash; OR &mdash;</span>
                    <button type="button" class="app-btn btn-block outline" (click)="resetUserNamePwd();onSignInUsername()">Sign in with
                        Username</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row h-100 m-0 login-form" *ngIf="isLoginOtpSent && !isLoginOtp && !isAppList">
    <div class="col-5 d-none d-lg-block">
        <div class="row img-login-wrap">
            <img src="assets/images/login/login1.png" class="img-login" />
        </div>
    </div>
    <div class="col-md-7 contents">
        <div class="row text-center m-0 p-4">
            <div class="col-4"></div>
            <div class="col-8 flex justify-content-end">
                <!-- <span> You haven't yet registered your school?</span>
                <button type="button" class="app-btn outline ml-2" (click)="backToLogin()">
                    Register
                </button> -->
            </div>
        </div>
        <div class="row justify-content-center right-panel">
            <div class="col-md-8">
                <div class="mb-4" style="margin-left: 2.25rem !important;">
                    <h3>OTP Verification</h3>
                    <p class="mb-4">Please enter the OTP you received to {{phone}}
                    </p>
                </div>
                <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                <form class="form-body" novalidate [formGroup]="otpForm" (keyup.enter)="login()">
                    <div class="form-group">
                        <div class="flex justify-center">
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpFirst (keyup)="autoMoveOtp($event,'otpSecond','otpFirst',1,'first')" formControlName="first"
                                maxlength="1" (focus)="checkFocus('otpFirst',1)" (click)="clearVal('otpFirst',1)" />
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpSecond (keyup)="autoMoveOtp($event,'otpThird','otpSecond',2,'second')" formControlName="second"
                                maxlength="1" (focus)="checkFocus('otpSecond',2)" (click)="clearVal('otpSecond',1)" (keydown)="deleteOtp($event,'otpSecond','otpFirst',2)"/>
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpThird (keyup)="autoMoveOtp($event,'otpFourth','otpThird',3,'third')" formControlName="third"
                                maxlength="1" (focus)="checkFocus('otpThird',3)" (click)="clearVal('otpThird',1)" (keydown)="deleteOtp($event,'otpThird','otpSecond',3)"/>
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpFourth (keyup)="autoMoveOtp($event,'otpFifth','otpFourth',4,'fourth')" formControlName="fourth"
                                maxlength="1" (focus)="checkFocus('otpFourth',4)" (click)="clearVal('otpFourth',1)" (keydown)="deleteOtp($event,'otpFourth','otpThird',4)"/>
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpFifth (keyup)="autoMoveOtp($event,'otpSixth','otpFifth',5,'fifth')" formControlName="fifth"
                                maxlength="1" (focus)="checkFocus('otpFifth',5)" (click)="clearVal('otpFifth',1)" (keydown)="deleteOtp($event,'otpFifth','otpFourth',5)"/>
                            <input
                                class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                type="text" #otpSixth formControlName="sixth" maxlength="1" (keyup)="autoMoveOtp($event,'','otpSixth',6,'sixth')" (focus)="checkFocus('otpSixth',1)" (click)="clearVal('otpSixth',1)" (keydown)="deleteOtp($event,'otpSixth','otpFifth',6)"/>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center">
                        <span><a href="#" class="forgot-pass" (click)="resendOTP()">Resend OTP</a></span>
                    </div>
                    <button type="button" class="app-btn btn-block mb-4" (click)="login()">Validate & Sign In</button>
                    <span><a href="#" class="forgot-pass" (click)="backToLogin()">Back to Sign In</a></span>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="row h-100 w-100 m-0 login-form" *ngIf="!isLoginOtpSent && !isLoginOtp && isAppList">
    <div class="col-5 d-none d-lg-block">
        <div class="row img-login-wrap">
            <img src="assets/images/login/login1.png" class="img-login" />
        </div>
    </div>
    <div class="col-md-7 contents">
        <div class="row text-center m-0 p-4">
            <div class="col-4"></div>
            <div class="col-8 flex justify-content-end">
                <span> You haven't yet registered your school?</span>
                <button *ngIf="guestUser && applist.length == 0" type="button" class="app-btn outline ml-2"
                    [routerLink]="'/institute-creation'">
                    Register
                </button>
                <button *ngIf="!guestUser && applist.length > 0" type="button" class="app-btn outline ml-2"
                    [routerLink]="'/institute-creation'">
                    Register
                </button>
                <button *ngIf="!guestUser && applist.length == 0" type="button" class="app-btn outline ml-2" (click)="backToLogin()">
                    Register
                </button>
            </div>
        </div>






        <div class="row justify-content-center right-panel" *ngIf="(applist && applist.length > 0 && (studentDataList && studentDataList.length > 0))">

            <div class="col-5" >

                <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                    <h3>Select Your Institute</h3>
                    <p class="mb-4" style="color: #717171; font-size: 14px;">Institutes associated with your registered
                        number are listed below.
                    </p>
                </div>
                <div class="app-list">
                    <div class="row">
                        <div class="col-lg-12" >
                            <div class="card card-margin" *ngFor="let app of applist">
                                <div class="card-body pt-0" style="cursor: pointer;" (click)="goToDashboard(app)">
                                    <div class="widget-49">
                                        <div class="widget-49-title-wrapper">
                                            <div class="widget-49-date-primary">
                                                <img [src]="app.imageSrc" class="img-app-list" />
                                            </div>
                                            <div class="widget-49-meeting-info">
                                                <span class="widget-49-pro-title">{{app.schoolName}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="app.orgLocation"><i
                                                    class="fa fa-map-marker mr-1"></i>{{app.orgLocation}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-1"></div>
            <div class="col-5"  *ngIf="studentDataList && studentDataList.length > 0">

                <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                    <h3>Select Your Child</h3>
                    <p class="mb-4" style="color: #717171; font-size: 14px;">Children associated with your registered
                        number are listed below.
                    </p>
                </div>
                <div class="app-list">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card card-margin" *ngFor="let student of studentDataList">
                                <div class="card-body pt-0" style="cursor: pointer;" (click)="goToStudentDashboard(student)">
                                    <div class="widget-49">
                                        <div class="widget-49-title-wrapper">
                                            <div class="widget-49-date-primary">
                                                <img [src]="student.imageSrc" class="img-app-list" />
                                            </div>
                                            <div class="widget-49-meeting-info">
                                                <span class="widget-49-pro-title">{{student?.studentName}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="student.className"><i
                                                    class="mr-1"></i>{{student.className}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="student?.orgName"><i
                                                    class="mr-1"></i>{{student?.orgName}}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-2"></div>

        </div>



        <div class="row justify-content-center right-panel" *ngIf="isApp && !studentDataList">
            <div class="col-md-8">
                <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                    <h3>Select Your Institute</h3>
                    <p class="mb-4" style="color: #717171; font-size: 14px;">Institute associated with your registered
                        number are listed below.
                    </p>
                </div>
                <div class="app-list">
                    <div class="row">
                        <div class="col-lg-12" *ngIf="applist && applist.length > 0">
                            <div class="card card-margin" *ngFor="let app of applist">
                                <div class="card-body pt-0" style="cursor: pointer;" (click)="goToDashboard(app)">
                                    <div class="widget-49">
                                        <div class="widget-49-title-wrapper">
                                            <div class="widget-49-date-primary">
                                                <img [src]="app.imageSrc" class="img-app-list" />
                                            </div>
                                            <div class="widget-49-meeting-info">
                                                <span class="widget-49-pro-title">{{app.schoolName}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="app.orgLocation"><i
                                                        class="fa fa-map-marker mr-1"></i>{{app.orgLocation}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="row justify-content-center right-panel" *ngIf="!isApp && studentDataList">
            <div class="col-md-8">
                <div class="mb-4 mt-2" style="margin-left: 0.25rem !important;">
                    <h3>Select Your Child</h3>
                    <p class="mb-4" style="color: #717171; font-size: 14px;">Children associated with your registered
                        number are listed below.
                    </p>
                </div>
                <div class="app-list">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card card-margin" *ngFor="let student of studentDataList">
                                <div class="card-body pt-0" style="cursor: pointer;" (click)="goToStudentDashboard(student)">
                                    <div class="widget-49">
                                        <div class="widget-49-title-wrapper">
                                            <div class="widget-49-date-primary">
                                                <img [src]="student.imageSrc" class="img-app-list" />
                                            </div>
                                            <div class="widget-49-meeting-info">
                                                <span class="widget-49-pro-title">{{student?.studentName}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="student.className"><i
                                                    class="mr-1"></i>{{student.className}}</span>
                                                <span class="widget-49-meeting-time" *ngIf="student?.orgName"><i
                                                    class="mr-1"></i>{{student?.orgName}}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center right-panel" *ngIf="!isApp && !studentDataList">
            <div class="row text-center m-0 p-4">
                <div class="col-1"></div>
                <div class="col-10 flex justify-content-end">
                    <span> You are not associated with any Institute. Would you like to onboard your Institute
                        Now?</span>

                </div>
                <div class="col-1"></div>
            </div>
            <div class="row text-center">
                <button type="button" class="app-btn ml-2" [routerLink]="'/institute-creation'">
                    Onboard Now
                </button>
            </div>
        </div>
    </div>
</div>
