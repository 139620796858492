import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from 'projects/common/src/public-api';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

const sharedModule = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppCommonModule,
    NgxChartsModule,
    SweetAlert2Module
];

const sharedDirectives = [
];

const sharedPipes = [
];

const sharedComponents = [
];

const sharedServices = []
@NgModule({
    imports: [
        ...sharedModule,
    ],
    declarations: [
        ...sharedDirectives,
        ...sharedComponents,
        ...sharedPipes,
    ],
    providers: [
        ...sharedServices,
    ],
    exports: [
        ...sharedModule,
        ...sharedDirectives,
        ...sharedComponents,
        ...sharedPipes
    ]
})
export class SharedModule { }
