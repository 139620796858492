
export const CommonConstant = {
    token: "token",
    user: "user",
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
};

export const Images = {
    user: "assets/images/default_user.png"
};