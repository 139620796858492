import { NgModule } from '@angular/core';
import { PublicRoutingComponents, PublicRoutingModule } from './public-routing.module';
import { SharedModule } from '../shared';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdmissionComponent } from './admission/admission.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';

@NgModule({
    declarations: [
        PublicRoutingComponents,
        ForgotPasswordComponent,
        AdmissionComponent,
        MobileLoginComponent
    ],
    imports: [
        PublicRoutingModule,
        SharedModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
    ],
    providers: [
    ]
})
export class PublicModule { }
