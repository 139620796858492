<div class="row h-100 w-100 m-0 login-form">
    <div class="col-5 d-none d-lg-block">
        <div class="row img-login-wrap">
            <img src="assets/images/login/login1.png" class="img-login"/>
        </div>
    </div>
    <div class="col-md-7 contents">
        <div class="row justify-content-center right-panel">
            <div class="col-md-8">
                <div *ngIf="isForgotPassword">

                    <div class="mb-4" style="margin-left: 2.25rem !important;">
                        <h3>Forgot Password</h3>
                    </div>
                    <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                    <form class="form-body" novalidate [formGroup]="forgotPasswordForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                <span class="has-float-label">
                                    <input type="text" class="form-control" formControlName="username"
                                           placeholder="Username" required="required" id="forgotUser">
                                    <label for="forgotUser" (click)="focusOnLabel('forgotUser')">Username <span
                                        class="required">*</span></label>
                                </span>
                                    <validation-message [control]="forgotPasswordForm.controls.username" message="Username"
                                                        [isFormSubmitted]="isForgotPasswordFormSubmitted">
                                    </validation-message>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="app-btn btn-block outline" (click)="submitForgotpassword()">Get OTP</button>


                        <div class="d-flex mt-4 align-items-center">
                            <span><a href="#" [routerLink]="'/login'" class="forgot-pass">Back To Sign in</a></span>
                        </div>
                    </form>

                </div>

                <div *ngIf="showOtp">
                    <div class="mb-4" style="margin-left: 2.25rem !important;">
                        <h3>OTP Verification</h3>
                        <p class="mb-4">Please enter the OTP you received to {{phone}}
                        </p>
                    </div>
                    <span *ngIf="error" class="alert alert-danger ml-5" role="alert">{{error}}</span>
                    <form class="form-body" novalidate [formGroup]="otpForm">
                        <div class="form-group">
                            <div class="flex justify-center">
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpFirst (keyup)="autoMoveOtp('otpSecond','otpFirst',1)" formControlName="first"
                                    maxlength="1" (focus)="checkFocus('otpFirst',1)" (click)="clearVal('otpFirst',1)"/>
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpSecond (keyup)="autoMoveOtp('otpThird','otpSecond',2)" formControlName="second"
                                    maxlength="1" (focus)="checkFocus('otpSecond',2)" (click)="clearVal('otpSecond',1)"
                                    (keydown)="deleteOtp($event,'otpSecond','otpFirst',2)"/>
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpThird (keyup)="autoMoveOtp('otpFourth','otpThird',3)" formControlName="third"
                                    maxlength="1" (focus)="checkFocus('otpThird',3)" (click)="clearVal('otpThird',1)"
                                    (keydown)="deleteOtp($event,'otpThird','otpSecond',3)"/>
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpFourth (keyup)="autoMoveOtp('otpFifth','otpFourth',4)" formControlName="fourth"
                                    maxlength="1" (focus)="checkFocus('otpFourth',4)" (click)="clearVal('otpFourth',1)"
                                    (keydown)="deleteOtp($event,'otpFourth','otpThird',4)"/>
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpFifth (keyup)="autoMoveOtp('otpSixth','otpFifth',5)" formControlName="fifth"
                                    maxlength="1" (focus)="checkFocus('otpFifth',5)" (click)="clearVal('otpFifth',1)"
                                    (keydown)="deleteOtp($event,'otpFifth','otpFourth',5)"/>
                                <input
                                    class="m-2 text-center otp-control otp-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                                    type="text" #otpSixth formControlName="sixth" maxlength="1" (focus)="checkFocus('otpSixth',1)"
                                    (click)="clearVal('otpSixth',1)" (keydown)="deleteOtp($event,'otpSixth','otpFifth',6)"/>
                            </div>
                        </div>
                        <div class="d-flex mb-4 align-items-center">
                            <span><a href="#" class="forgot-pass" (click)="submitForgotpassword()">Resend OTP</a></span>
                        </div>
                        <button type="button" class="app-btn btn-block mb-4" (click)="validateOtp()">Validate & Reset Password</button>
<!--                        <span><a href="#" [routerLink]="'/login'" class="forgot-pass">Back To Sign in</a></span>-->
                    </form>
                </div>

                <div *ngIf="isResetPassword">
                    <div>
                        <div class="mb-4" style="margin-left: 2.25rem !important;">
                            <span *ngIf="isMessage" class="alert alert-success  " role="alert">Your OTP has been verified, Please enter new password</span> <br/>

                            <h3 class="mt-3">Reset Password</h3>
                        </div>
                        <span *ngIf="error" class="alert alert-danger ml-5 mb-3" role="alert">{{error}}</span>

                        <form class="form-body" novalidate [formGroup]="resetPasswordForm">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group input-group last mb-4">
                                <span class="has-float-label">
                                    <input [type]="newPasswordFieldTextType ? 'text' : 'password'" class="form-control"
                                           formControlName="newPassword" placeholder="New Password" id="newpassword" required="required">
                                    <label for="newpassword" (click)="focusOnLabel('newpassword')">New Password <span
                                        class="required">*</span></label>
                                </span>
                                        <div class="input-group-append">
                                    <span class="input-group-text" (click)="toggleNewPasswordFieldTextType()">
                                        <i class="fa" [ngClass]="{
                                          'fa-eye-slash': !newPasswordFieldTextType,
                                          'fa-eye': newPasswordFieldTextType
                                        }"></i>
                                    </span>
                                        </div>
                                    </div>
                                    <validation-message [control]="resetPasswordForm.controls.newPassword" message="New Password"
                                                        [isFormSubmitted]="isFormSubmitted">
                                    </validation-message>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group input-group last mb-4">
                                <span class="has-float-label">
                                    <input [type]="confirmPasswordFieldTextType ? 'text' : 'password'" class="form-control"
                                           formControlName="confirmPassword" placeholder="Confirm Password" id="confirmPassword"
                                           required="required">
                                    <label for="confirmPassword" (click)="focusOnLabel('confirmPassword')">Confirm Password <span
                                        class="required">*</span></label>
                                </span>
                                        <div class="input-group-append">
                                    <span class="input-group-text" (click)="toggleConfirmPasswordFieldTextType()">
                                        <i class="fa" [ngClass]="{
                                          'fa-eye-slash': !confirmPasswordFieldTextType,
                                          'fa-eye': confirmPasswordFieldTextType
                                        }"></i>
                                    </span>
                                        </div>
                                    </div>
                                    <validation-message [control]="resetPasswordForm.controls.confirmPassword" message="Confirm Password"
                                                        [isFormSubmitted]="isFormSubmitted">
                                    </validation-message>
                                </div>
                            </div>

                            <div *ngIf="(resetPasswordForm.controls.confirmPassword.errors && isFormSubmitted) "
                                  class="alert alert-danger mt-3 mb-3" role="alert">New Passsword and
                                Confirm Password didn't match.
                            </div>
                            <button type="button" class="app-btn btn-block" (click)="submitConfirmPassword()">Reset</button>

                            <div *ngIf="isMessage" class="d-flex mt-4 align-items-center">
                                <span><a href="#" [routerLink]="'/login'" class="forgot-pass">Back</a></span>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<swal #issueSwal title="" [allowOutsideClick]="false" text="Password has been updated successfully." icon="success" (close)="redirectToLogin()">
</swal>
