import { Component, ElementRef, ViewChild } from "@angular/core";
import { PublicService } from "../public.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginRequest } from "models";
import { ActivatedRoute, Router } from "@angular/router";
import { ThemeHelpersService, UserAuthService } from "projects/common/src/public-api";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonConstant, UploadService, ValidationService } from "projects/common/src/lib";
import { Images } from "@school-core";

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ["login.component.scss"],
})
export class LoginComponent {
    hide: boolean;
    user: LoginRequest;
    frmLogin: FormGroup;
    otpForm: FormGroup;
    isFormSubmitted: boolean;
    error: string;
    fieldTextType = false;
    isLoginOtp = true;
    isLoginOtpSent = false;
    isAppList = false;
    isApp = false;
    phone: any;
    applist: any = [];
    route: any;
    token: any;
    guestUser = false;
    isLogin = true;
    otpIdx = 0;
    studentDataList: any;
    studentProfilePicList: any = [];

    @ViewChild('otpFirst', { static: false }) otpFirst: ElementRef;
    @ViewChild('otpSecond', { static: false }) otpSecond: ElementRef;
    @ViewChild('otpThird', { static: false }) otpThird: ElementRef;
    @ViewChild('otpFourth', { static: false }) otpFourth: ElementRef;
    @ViewChild('otpFifth', { static: false }) otpFifth: ElementRef;
    @ViewChild('otpSixth', { static: false }) otpSixth: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private publicService: PublicService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userServive: UserAuthService,
        private spinner: NgxSpinnerService,
        private uploadService: UploadService,
        private themeHelper: ThemeHelpersService
    ) {
        this.createForm();
        this.createOTP();
    }

    private createForm() {
        this.frmLogin = this.formBuilder.group({
            password: new FormControl('')
        });
        if (this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required, ValidationService.phoneNoValidator]));
        } else if (!this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required]));
        }
    }

    private createOTP() {
        this.otpForm = this.formBuilder.group({
            first: ['', Validators.required],
            second: ['', Validators.required],
            third: ['', Validators.required],
            fourth: ['', Validators.required],
            fifth: ['', Validators.required],
            sixth: ['', Validators.required]
        });
    }

    get username() {
        return this.frmLogin.get("username");
    }

    focusOnLabel(selector) {
        document.getElementById(selector).focus()
    }

    resetUserNamePwd() {
        this.frmLogin.reset();
        // this.frmLogin.controls['password'].patchValue('');
        // this.frmLogin.controls['username'].patchValue('');
    }

    login() {
        this.isFormSubmitted = true;
        this.frmLogin.updateValueAndValidity();
        // if (this.frmLogin.invalid) {
        //     return;
        // }
        this.user = this.frmLogin.value;
        this.error = null;
        if (!this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            if (!this.frmLogin.valid) {
                return;
            }
            this.spinner.show();
            this.publicService.login(this.user).subscribe(
                (result) => {
                    if(result['isPasswordChangeRequired'] === 'Y') {
                        this.router.navigate(['/forgot-password']);
                        setTimeout(()=> {
                            result['username'] = this.user.username;
                            this.themeHelper.updateListener('redirect:reset', result);
                        }, 500);
                    } else {
                        this.route = result['route'];
                        this.token = result['token'];
                        this.spinner.hide();
                        if (result['isGuestUser'] == true) {
                            this.guestUser = true;
                            this.userServive.saveToken(result['token']);
                            this.isLoginOtpSent = false;
                            this.isLoginOtp = false;
                            this.isAppList = true;
                            this.isApp = false;
                        } else {
                            this.userServive.saveToken(result['token']);
                            this.publicService.getUserAccess(this.frmLogin.value["username"]).subscribe(user => {
                                if (user && user.length > 0) {
                                    this.isLoginOtpSent = false;
                                    this.isLoginOtp = false;
                                    this.isAppList = true;
                                    this.isApp = true;
                                    this.applist = user;
                                    if (this.applist && this.applist.length > 0) {
                                        console.log("applist", this.applist);
                                        this.applist.forEach(item => {
                                            const params = {
                                                module: 'School',
                                                fileName: 'logo',
                                                contentType: 'image/jpeg'
                                            }
                                            if (item.orgCode) {
                                                this.userServive.saveOrgCode(item.orgCode);
                                            }
                                            this.uploadService.getDocument(params).subscribe((data) => {
                                                if (data.status != 406) {
                                                    item.imageSrc = data ? data.URL : Images.school;
                                                    const schoolProfilePic = {
                                                        schoolUniqueId: item.userUniqueId,
                                                        image: item.imageSrc
                                                    }
                                                    this.studentProfilePicList.push(schoolProfilePic);
                                                } else {
                                                    item.imageSrc = Images.school;
                                                }
                                            }, err => {
                                                item.imageSrc = Images.school;
                                            });
                                        });
                                    }
                                }
                            }, err => {
                                if (err.status == 406) {
                                    this.isLoginOtpSent = false;
                                    this.isLoginOtp = false;
                                    this.isAppList = true;
                                    this.isApp = false;
                                }
                            });
    
                            this.spinner.show();
                            this.publicService.getUserAccessForParent(this.frmLogin.value["username"]).subscribe((res : any) => {
                                console.log("student data", res);
                                if(res) {
                                    this.studentDataList = res;
                                    this.spinner.hide();
                                    if (this.studentDataList && this.studentDataList.length > 0) {
                                        this.studentDataList.forEach(item => {
                                            let color = Math.floor(0x1000000 * Math.random()).toString(16);
                                            item['color'] = '#' + ('000000' + color).slice(-6);
                                            // item['color'] = "#" + ((1<<24)*Math.random() | 0).toString(16);
                                        });
                                        this.studentDataList.forEach(item => {
                                            if (item.orgCode) {
                                                this.userServive.saveOrgCode(item.orgCode);
                                            }
                                            const params = {
                                                module: 'Students',
                                                fileName: item.userUniqueId,
                                                contentType: 'image/jpeg'
                                            }
                                            this.uploadService.getDocument(params).subscribe((data) => {
                                                if (data.status != 406) {
                                                    item.imageSrc = data ? data.URL : Images.school;
                                                    const studentProfilePic = {
                                                        studentProfileId : item.userUniqueId,
                                                        image: item.imageSrc
                                                    }
                                                    this.studentProfilePicList.push(studentProfilePic);
                                                } else {
                                                    item.imageSrc = Images.school;
                                                }
                                            }, err => {
                                                item.imageSrc = Images.school;
                                            });
                                            console.log("student profile pic", this.studentProfilePicList);
                                        });
                                    }
    
                                }
                            }, error1 => {
                                this.spinner.hide();
                            })
                        }
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.error = err['error'].message;
                }
            );
        } else if (this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            if (!this.frmLogin.valid) {
                return;
            }
            this.spinner.show();
            this.publicService.sendOTP(this.user.username).subscribe(
                (result) => {
                    this.spinner.hide();
                    this.isLoginOtpSent = true;
                    this.isLoginOtp = false;
                    this.phone = this.user.username;
                },
                (err) => {
                    this.spinner.hide();
                    if (err.status == 417) {
                        this.error = err['error'].message;
                    } else {
                        this.error = "Invalid mobile number";
                    }
                }
            );
        } else if (!this.isLoginOtp && this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.value['password'] = Object.values(this.otpForm.value).join('');
            this.spinner.show();

            setTimeout(() => { this.spinner.hide(); }, 3000);

            this.publicService.validateOTP(this.user).subscribe(
                (result) => {
                    this.route = result['route'];
                    this.token = result['token'];
                    this.spinner.hide();
                    if (result['isGuestUser'] == true) {
                        this.guestUser = true;
                        this.userServive.saveToken(result['token']);
                        this.isLoginOtpSent = false;
                        this.isLoginOtp = false;
                        this.isAppList = true;
                        this.isApp = false;
                    } else {
                        this.userServive.saveToken(result['token']);
                        this.publicService.getUserAccess(this.frmLogin.value["username"]).subscribe(user => {
                            if (user && user.length > 0) {
                                this.isLoginOtpSent = false;
                                this.isLoginOtp = false;
                                this.isAppList = true;
                                this.isApp = true;
                                this.applist = user;
                                if (this.applist && this.applist.length > 0) {
                                    this.applist.forEach(item => {
                                        if (item.orgCode) {
                                            this.userServive.saveOrgCode(item.orgCode);
                                        }
                                        const params = {
                                            module: 'School',
                                            fileName: 'logo',
                                            contentType: 'image/jpeg'
                                        }
                                        this.uploadService.getDocument(params).subscribe((data) => {
                                            if (data.status != 406) {
                                                item.imageSrc = data ? data.URL : Images.school;
                                                const schoolProfilePic = {
                                                    schoolUniqueId: item.userUniqueId,
                                                    image: item.imageSrc
                                                }
                                                this.studentProfilePicList.push(schoolProfilePic);
                                            } else {
                                                item.imageSrc = Images.school;
                                            }
                                        }, err => {
                                            item.imageSrc = Images.school;
                                        });
                                        console.log("student profile pi 1", this.studentProfilePicList);

                                    });
                                }
                            }
                        }, err => {
                            if (err.status == 406) {
                                this.isLoginOtpSent = false;
                                this.isLoginOtp = false;
                                this.isAppList = true;
                                this.isApp = false;
                            }
                        });
                        this.spinner.show();
                        this.publicService.getUserAccessForParent(this.frmLogin.value["username"]).subscribe((res : any) => {
                            console.log("student data", res);
                            if(res) {
                                this.studentDataList = res;
                                this.spinner.hide();
                                if (this.studentDataList && this.studentDataList.length > 0) {
                                    this.studentDataList.forEach(item => {
                                        let color = Math.floor(0x1000000 * Math.random()).toString(16);
                                        item['color'] = '#' + ('000000' + color).slice(-6);
                                        // item['color'] = "#" + ((1<<24)*Math.random() | 0).toString(16);
                                    });
                                    this.studentDataList.forEach(item => {
                                        if (item.orgCode) {
                                            this.userServive.saveOrgCode(item.orgCode);
                                        }
                                        const params = {
                                            module: 'Students',
                                            fileName: item.userUniqueId,
                                            contentType: 'image/jpeg'
                                        }
                                        this.uploadService.getDocument(params).subscribe((data) => {
                                            if (data.status != 406) {
                                                item.imageSrc = data ? data.URL : Images.school;
                                                const studentProfilePic = {
                                                    studentProfileId : item.userUniqueId,
                                                    image: item.imageSrc
                                                }
                                                this.studentProfilePicList.push(studentProfilePic);
                                            } else {
                                                item.imageSrc = Images.school;
                                            }
                                        }, err => {
                                            item.imageSrc = Images.school;
                                        });
                                        console.log("student profile pic", this.studentProfilePicList);
                                    });
                                }
                            }
                        }, error1 => {
                            this.spinner.hide();
                        })
                    }
                },
                (error) => {
                    this.spinner.hide();
                    if (error.status == 417) {
                        this.error = error['error'].message;
                    } else {
                        this.error = "Invalid OTP";
                    }
                });
        }
    }

    checkFocus(clearCurrent, idx) {
        if (idx < this.otpIdx) {
            //this[clearCurrent].nativeElement.value = '';
        }
        this.otpIdx = idx;
    }

    deleteOtp($event, clearCurrent, prevEl, idx) {
        if ($event.key === "Backspace") {
            if (this[clearCurrent].nativeElement.value.length > 0) {
                this[clearCurrent].nativeElement.value = '';
            } else {
                this[prevEl].nativeElement.value = '';
                this[prevEl].nativeElement.focus();
            }
        }

    }

    clearVal(clearCurrent, idx) {
        this[clearCurrent].nativeElement.value = '';
    }

    autoMoveOtp(event, nativeElemName, clearCurrent, idx, controlname) {
        if(event.keyCode == 8) {
            this.otpForm.value[controlname] = '';
        }
        if (this[clearCurrent].nativeElement.value.length == 0) {
            return;
        }

        this.otpIdx = idx;
        if(nativeElemName) {
            this[nativeElemName].nativeElement.focus();
        }
    }

    resendOTP() {
        this.isFormSubmitted = true;
        if (this.frmLogin.invalid) {
            return;
        }
        this.error = null;
        this.otpForm.reset();
        this.user = this.frmLogin.value;
        this.spinner.show();
        this.publicService.sendOTP(this.user.username).subscribe(
            (result) => {
                this.spinner.hide();
                this.isLoginOtpSent = true;
                this.isLoginOtp = false;
                this.isAppList = false;
                this.phone = this.user.username;
            },
            (error) => {
                this.spinner.hide();
                this.error = "Invalid mobile number";
            }
        );

    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    onSignInUsername() {
        this.isLoginOtpSent = false;
        this.isLoginOtp = false;
        this.isAppList = false;
        this.isLogin = true;
        this.frmLogin.removeControl('username');
        if (this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required, ValidationService.phoneNoValidator]));
            this.frmLogin.get('password').clearValidators();
            this.frmLogin.updateValueAndValidity();
        } else if (!this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required]));
            this.frmLogin.get('password').setValidators(Validators.required);
            this.frmLogin.updateValueAndValidity();
        }
    }

    onSignInOTP() {
        this.isLoginOtpSent = false;
        this.isLoginOtp = true;
        this.isAppList = false;
        this.isLogin = true;
        this.frmLogin.removeControl('username');
        if (this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required, ValidationService.phoneNoValidator]));
            this.frmLogin.get('password').clearValidators();
            this.frmLogin.updateValueAndValidity();
        } else if (!this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required]));
            this.frmLogin.get('password').setValidators(Validators.required);
            this.frmLogin.updateValueAndValidity();
        }
    }

    backToLogin() {
        this.frmLogin.reset();
        this.otpForm.reset();
        this.isLoginOtpSent = false;
        this.isLoginOtp = true;
        this.isAppList = false;
        this.route = null;
        this.isLogin = false;
        this.frmLogin.removeControl('username');
        if (this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required, ValidationService.phoneNoValidator]));
        } else if (!this.isLoginOtp && !this.isLoginOtpSent && !this.isAppList) {
            this.frmLogin.addControl('username', new FormControl('', [Validators.required]));
        }
    }

    goToDashboard(app) {
        this.userServive.saveEmployeeName(app.employeeName);
        this.userServive.saveEmployeeId(app.userUniqueId);
        this.userServive.saveSchoolName(app.schoolName);
        this.userServive.saveOrgCode(app.orgCode);
        this.userServive.saveAppName(app?.appName);
        this.userServive.saveUserName(this.frmLogin.value["username"]);
       let shortUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectURL') || ''
        setTimeout(() => {
            if(shortUrl) {
                const qparams = btoa(JSON.stringify({
                    token: window.sessionStorage.getItem(CommonConstant.token),
                    code:app.orgCode,
                    appName:app.appName,
                    employeeId: app.userUniqueId,
                    employeeName: app.employeeName,
                    schoolName: app.schoolName,
                    userName: this.frmLogin.value["username"],
                }));
                window.location.href = `${shortUrl}?query=${qparams}`;
            } else {
                window.location.href = `${app?.route}/validate?token=${window.sessionStorage.getItem(CommonConstant.token)}&code=${app.orgCode}&appName=${app.appName}&employeeId=${app.userUniqueId}&employeeName=${app.employeeName}&schoolName=${app.schoolName}&userName=${this.frmLogin.value["username"]}`;
            }
        }, 500);
    }


    goToStudentDashboard(app) {
        this.userServive.saveEmployeeName(app.studentName);
        this.userServive.saveStudentProfileId(app.userUniqueId);
        this.userServive.saveSchoolName(app.schoolName);
        this.userServive.saveOrgCode(app.orgCode);
        this.userServive.saveAppName(app?.appName);
        this.userServive.saveUserName(this.frmLogin.value["username"]);
        setTimeout(() => {
            window.location.href = `${app.route}/validate?token=${window.sessionStorage.getItem(CommonConstant.token)}&code=${app.orgCode}&studentProfileId=${app.userUniqueId}&studentName=${app.studentName}&schoolName=${app.schoolName}&appName=${app.appName}&id=${app.userUniqueId}&userName=${this.frmLogin.value["username"]}`;
        }, 500);
    }

    dynamicClass() {
        if (this.studentDataList && this.studentDataList.length > 0) {
            return 'right-panel-data';
        } else {
            return 'right-panel';
        }

    }
}
