import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService, CommonUtility } from 'projects/common/src/public-api';
import { APIConstant } from '../core';
import { LoginResponse, LoginRequest, Admission } from 'models';

@Injectable({
    providedIn: "root"
})
export class PublicService {

    constructor(private baseService: BaseService) {
    }

    login(user: LoginRequest): Observable<LoginResponse> {
        const params = CommonUtility.convertObjectToUrlEncoded(user);
        return this.baseService.post(`${APIConstant.login}`, params);
    }

    getUserAccess(user: any): Observable<any> {
        return this.baseService.get(`${APIConstant.userAccess}?username=${user}&appName=Spectrum`);
    }

    sendOTP(mobile: any){
        return this.baseService.post(`${APIConstant.sendotp}?mobile=${mobile}`, null);
    }

    validateOTP(data: any){
        return this.baseService.post(`${APIConstant.validateotp}?password=${data.password}&username=${data.username}`, null);
    }

    addSchool(data: any){
        return this.baseService.post(`${APIConstant.schoolcreation}`, data);
    }

    getSchool(){
        return this.baseService.get(`${APIConstant.schoolcreation}`)
    }

    createDefaultSetup(schoolCode: any){
        return this.baseService.post(`${APIConstant.defaultSetup}?schoolCode=${schoolCode}`, null);
    }

    // getUser(username: string): Observable<User> {
    //     const params = CommonUtility.convertObjectToParams({ username });
    //     return this.baseService.get(`${APIConstant.user}?${params}`);
    // }
    forgotPassword(username: any) {
        return this.baseService.post(`${APIConstant.sendotp}?email=${username}&otpModule=RESET_PASSSWORD`, null);
    }


    changePassword(data: any) {
        return this.baseService.post(`${APIConstant.changePassword}?username=${data.username}&password=${data.password}`, null);
    }

    getUserAccessForParent(user: any): Observable<any> {
        return this.baseService.get(`${APIConstant.userAccess}?username=${user}&appName=SmartParent`);
    }

    submitAdmission(data: Admission): Observable<Admission> {
        return this.baseService.post(`${APIConstant.admissionData}`, data);
    }
}
