import { Injectable } from "@angular/core";
import { Admission, APIPagedResponse } from 'models';
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { Observable } from 'rxjs';
import { APIConstant } from "../constant";

@Injectable({
    providedIn: "root",
})
export class AdmissionService extends CRUDService<any> {

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.enquiries + "/");
    }

    getEnquiries(params: any): Observable<APIPagedResponse<Admission>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.enquiriesReport}?${query}`);
    }

    basicInfo(enquiryId: number): Observable<Admission> {
        return this.baseService.get<Admission>(`${APIConstant.enquiries}?enquiryId=${enquiryId}`);
    }

    search(search: any): Observable<APIPagedResponse<Admission>> {
        const params = CommonUtility.convertObjectToParams(search);
        return this.baseService.get<APIPagedResponse<Admission>>(`${APIConstant.searchEnquiry}?${params}`);
    }

    searchEnquiry(params: any): Observable<APIPagedResponse<Admission>> {
        return this.baseService.get<APIPagedResponse<Admission>>(`${APIConstant.searchEnquiry}?${params}`);
    }

    getAdmissionForm(data: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<any>(`${APIConstant.admissionForm}?${params}`);
    }

    getEnquiryFormSettings() {
        return this.baseService.get<any>(`${APIConstant.enquiryForms}`);
    }

    addEnquiryFormSetting(data: any) {
        return this.baseService.post<any>(`${APIConstant.enquiryForms}`, data);
    }

    updateEnquiryFormSetting(data: any) {
        return this.baseService.put<any>(`${APIConstant.enquiryForms}`, data);
    }

    deleteEnquiryFormSetting(id: any) {
        return this.baseService.delete<any>(`${APIConstant.enquiryForms}?id=${id}`);
    }

    updateList(list: any) {
        return this.baseService.put<any>(`${APIConstant.enquiryForms}/list`, list);
    }

    getApplicationFeeSetting() {
        return this.baseService.get<any>(`${APIConstant.admissionFeeBySchoolCode}`);
    }

    addApplicationFeeSetting(data: any) {
        return this.baseService.post<any>(`${APIConstant.admissionFeeSetting}/addAdmissionFeeSetting`, data);
    }

    updateApplicationFeeSetting(data: any) {
        return this.baseService.put<any>(`${APIConstant.admissionFeeSetting}/updateAdmissionFeeSetting`, data);
    }

    feeHeadsForMakePayment(params: any): Observable<any[]> {
        const reqParams = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any[]>(`${APIConstant.feeHeadsForPayment}?${reqParams}`);
    }

    makeFeePayment(paymentDetails: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.admissionMakePayment}`, paymentDetails);
    }
}
