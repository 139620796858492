import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { EnquiryContact } from 'models';
import { APIConstant } from "../constant";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class EnquiryContactService extends CRUDService<EnquiryContact> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.enquiryContact);
    }

    getContactById(enquiryId: number): Observable<EnquiryContact> {
        return this.baseService.get<EnquiryContact>(`${APIConstant.enquiryContact}?enquiryId=` + enquiryId);
    }

    addOrUpdateContact(data: EnquiryContact): Observable<EnquiryContact> {
        return this.baseService.post(`${APIConstant.enquiryContact}`, data);
    }

    deleteContact(id: number): Observable<any> {
        return this.baseService.delete(`${APIConstant.enquiryContact}?enquiryId=${id}`);
    }
}
