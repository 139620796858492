import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { Timetable } from "models";

@Injectable({
    providedIn: "root",
})
export class TimetableService extends CRUDService<Timetable> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.leaveRequests);
    }

    getTimeTable(employeeProfileId: number, date?: string, classroomId?: number): Observable<any[]> {
        const query = CommonUtility.convertObjectToParams({
            classroomId: classroomId,
            employeeProfileId: employeeProfileId,
            date: date,
        });
        return this.baseService.get(`${APIConstant.timetable}?${query}`);
    }
}
