
export const CommonConstant = {
    token: "token",
    orgCode: "orgCode",
    user: "user",
    libraryCode: "libraryCode",
    memberId: "memberId",
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
    importEmployeeExcelFile: 'https://akshar-public-files.s3.ap-south-1.amazonaws.com/Import+Templates/Employee+Import.xlsx',
};

export const Images = {
    user: "assets/images/user.png",
    book: "assets/images/add-book.png",
};

export const ChartColor = {
    domain: ["#FAC51D", "#66BD6D", "#FAA026", "#29BB9C", "#E96B56", "#55ACD2", "#B7332F", "#2C83C9", "#9166B8", "#92E7E8"],
};
