import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CRUDService, BaseService, CommonUtility, DateUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { Attendance } from "models";
@Injectable({
    providedIn: "root",
})
export class AttendanceService extends CRUDService<Attendance> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.attendance);
    }

    getAttendanceByDateRange(employeeProfileId: number, fromDate: any, toDate: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams({
            employeeProfileId: employeeProfileId,
            fromDate: DateUtility.dateToString(fromDate),
            toDate: DateUtility.dateToString(toDate),
        });
        return this.baseService.get(`${APIConstant.attendance}?${query}`);
    }
}
