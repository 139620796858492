import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    // Employee
    employee: `${apiPath}/hr/employees`,
    all: `${apiPath}/hr/employees/all`,
    allEmployee: `${apiPath}/hr/employees/search/datatable`,
    searchEmployee: `${apiPath}/hr/employees/search`,
    employeeContactDetails: `${apiPath}/employees/contactDetails`,
    employeeMissingInfo: `${apiPath}/hr/employees/missingInfo/`,
    employeeImport: `${apiPath}/hr/employees/import`,

    // Leave Mgt
    leaveRequest: `${apiPath}/hr/leave-mgmt/leaveRequest/`,
    leaveRequests: `${apiPath}/hr/leave-mgmt/leaveRequests`,
    leaveBalance: `${apiPath}/hr/leave-mgmt/leaveBalance`,
    leaveSetup: `${apiPath}/hr/leave-mgmt/leaveSetup`,
    leaveRequestsOfEmployee: `${apiPath}/hr/leave-mgmt/leaveRequests/byEmployee`,
    leaveTransaction: `${apiPath}/hr/leave-mgmt/transactions`,

    //Timetable
    timetable: `${apiPath}/academics/timetable`,

    //Attendance
    attendance: `${apiPath}/attendance`,
    empBankAccount: `${apiPath}/finance/emp-bank-accounts`,
    empBankAccountMyProfile: `${apiPath}/finance/emp-bank-accounts/myProfile`,
    employeeReport: `${apiPath}/hr/employees/report`,
    loginEmployee: `${apiPath}/hr/employees/login-employee`,

    birthdayReport: `${apiPath}/hr/employees/birthdays`,
    subjectMappedToTeacher: `${apiPath}/academics/subjects/subjects-mapped-to-teacher`,

    advanceSearchEmployee: `${apiPath}/hr/employees/search/v2`,
    currentAcademicYear: `${apiPath}/academics/academic-year-setup/current-academic-year`,
    validateMobile: `${apiPath}/hr/employees/validate-mobile`,
    validateEmail: `${apiPath}/hr/employees/validate-email`,
    getClass:`${apiPath}/admissions/enquiries/getAdmisssions/byAcademicYear`,
    getEnquiry:`${apiPath}/admissions/enquiries/getEnquiries/byAcademicYearAndClass`,
    postInfo:`${apiPath}/hr/employees/search-data`
};

export const PublicAPI = [];
