import { Injectable } from "@angular/core";
import { CRUDService, BaseService } from "projects/common/src/public-api";
import { EnquiryNotes } from 'models';
import { APIConstant } from "../constant";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class EnquiryNotesService extends CRUDService<EnquiryNotes> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.enquiryNotes);
    }

    getNotes(enquiryId: number): Observable<EnquiryNotes[]> {
        return this.baseService.get<EnquiryNotes[]>(`${APIConstant.enquiryNotes}?enquiryId=` + enquiryId);
    }

    addOrUpdateNote(data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.enquiryNotes}`, data);
    }

    addNote(data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.enquiryNotes}`, data);
    }

    updateNote(data: any): Observable<any> {
        return this.baseService.put(`${APIConstant.enquiryNotes}`, data);
    }
}
