import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant } from "../constant";
import { LeaveRequest, APIPagedResponse, LeavePolicy } from "models";

@Injectable({
    providedIn: "root",
})
export class LeaveService extends CRUDService<LeaveRequest> {
    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.leaveRequests);
    }

    getAllRequests(params: any): Observable<APIPagedResponse<LeaveRequest>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.leaveRequests}?${query}`);
    }

    deleteLeaveRequest(leaveRequestId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.leaveRequests}/${leaveRequestId}`);
    }

    addLeaveRequest(leaveData: LeaveRequest): Observable<any> {
        return this.baseService.post(`${APIConstant.leaveRequest}`, leaveData);
    }

    updateLeaveRequest(leaveRequestId: number, leaveData: LeaveRequest): Observable<any> {
        return this.baseService.put(`${APIConstant.leaveRequests}/${leaveRequestId}`, leaveData);
    }

    employeeLeaveBalance(employeeProfileId: number): Observable<any> {
        const query = CommonUtility.convertObjectToParams({
            employeeProfileId: employeeProfileId,
        });
        return this.baseService.get(`${APIConstant.leaveBalance}?${query}`);
    }

    getLeavePolicy(): Observable<any> {
        return this.baseService.get(`${APIConstant.leaveSetup}`);
    }

    addLeavePolicy(policyData: LeavePolicy[]): Observable<any> {
        return this.baseService.post(`${APIConstant.leaveSetup}/`, policyData);
    }

    updateLeavePolicy(leaveSetupId: number, policyData: LeavePolicy): Observable<any> {
        return this.baseService.put(`${APIConstant.leaveSetup}/${leaveSetupId}`, policyData);
    }

    deleteLeavePolicy(leaveSetupId: number): Observable<any> {
        return this.baseService.delete(`${APIConstant.leaveSetup}/${leaveSetupId}`);
    }

    getLeaveTransaction(params: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.leaveTransaction}?${query}`);
    }

    addLeaveTransaction(params: any) {
        return this.baseService.post(`${APIConstant.leaveTransaction}`, params);
    }

    updateLeaveTransaction(params: any){
        return this.baseService.put(`${APIConstant.leaveTransaction}`, params);
    }
}
