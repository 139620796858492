import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    AppCommonModule, AuthInterceptor, ResponseInterceptor, AppConfigModule
} from 'projects/common/src/public-api';
import { PublicModule } from './public/public.module';
import { CoreModule, PublicAPI, APIConstant } from './core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        PublicModule,
        AppRoutingModule,
        AppCommonModule,
        CoreModule,
        SweetAlert2Module.forRoot(),
        AppConfigModule.forRoot({
            publicAPIs: PublicAPI,
            apiPath: APIConstant.basePath
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
