import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAuthService, ValidationService } from "projects/common/src/public-api";
import { PublicService } from "../public.service";

@Component({
    selector: 'app-mobile-login',
    templateUrl: './mobile-login.component.html',
    styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {
    frmLogin: FormGroup;
    error: string;
    isFormSubmitted: boolean;
    isLoginOtp = true;
    isLoginOtpSent = false;
    phone: any;
    token: any;

    ngOnInit(): void {
        this.createForm();
    }

    constructor(private spinner: NgxSpinnerService,
        private publicService: PublicService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserAuthService) {
            this.route.queryParams.subscribe((params) => {
                if(params && params['schoolCode']) {
                    this.userService.saveOrgCode(params['schoolCode']);
                }
            });
    }

    private createForm() {
        this.frmLogin = this.formBuilder.group({
            username: new FormControl('', [Validators.required, ValidationService.phoneNoValidator])
        });
    }

    get username() {
        return this.frmLogin.get("username");
    }

    login() {
        this.isFormSubmitted = true;
        this.frmLogin.updateValueAndValidity();
        // if (this.frmLogin.invalid) {
        //     return;
        // }
        this.error = null;
        if (this.isLoginOtp && !this.isLoginOtpSent) {
            if (!this.frmLogin.valid) {
                return;
            }
            this.spinner.show();
            this.publicService.sendOTP(this.frmLogin.value.username).subscribe(
                (result) => {
                    this.spinner.hide();
                    this.isLoginOtpSent = true;
                    this.isLoginOtp = false;
                    this.phone = this.frmLogin.value.username;
                    this.frmLogin.addControl('password', new FormControl('', [Validators.required, Validators.maxLength(6)]));
                    this.frmLogin.updateValueAndValidity();
                },
                (err) => {
                    this.spinner.hide();
                    if (err.status == 417) {
                        this.error = err['error'].message;
                    } else {
                        this.error = "Invalid mobile number";
                    }
                }
            );
        } else if (!this.isLoginOtp && this.isLoginOtpSent) {
            // this.frmLogin.value['password'] = Object.values(this.otpForm.value).join('');
            this.spinner.show();

            setTimeout(() => { this.spinner.hide(); }, 3000);

            this.publicService.validateOTP(this.frmLogin.value).subscribe(
                (result) => {
                    this.token = result['token'];
                    this.userService.saveToken(result['token']);
                    setTimeout(() => {
                        this.router.navigate(['/admission']);
                    }, 600);
                    this.spinner.hide();
                },
                (error) => {
                    this.spinner.hide();
                    if (error.status == 417) {
                        this.error = error['error'].message;
                    } else {
                        this.error = "Invalid OTP";
                    }
                });
        }
    }
}