import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from 'projects/common/src/public-api';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'login'},
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./secure/secure.module').then(m => m.SecureModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
