import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PublicComponent } from './public.component';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ServerUnavailableComponent } from './server-unavailable/server-unavailable.component';
import { SchoolCreationComponent } from './school-creation/school-creation.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import { AdmissionComponent } from './admission/admission.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';

const routes: Routes = [
    {
        path: '',
        component: PublicComponent,
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full', },
            { path: 'login', component: LoginComponent },
            { path: 'unauthorized', component: UnauthorizedComponent },
            { path: 'server-unavailable', component: ServerUnavailableComponent },
            { path: 'institute-creation', component: SchoolCreationComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            { path: 'admission', component: AdmissionComponent },
            { path: 'mobile-login', component: MobileLoginComponent },
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PublicRoutingModule { }

export const PublicRoutingComponents = [
    LoginComponent, PublicComponent, UnauthorizedComponent,
    ServerUnavailableComponent, SchoolCreationComponent
];
