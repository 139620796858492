import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { PublicService } from "../public.service";
import { Images } from "@school-core";
import { ThemeHelpersService, UserAuthService } from "../../../../../common/src/lib/service";
import { CommonConstant } from "../../../../../common/src/lib/constants";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName];
        if (
            matchingControl.errors &&
            !matchingControl.errors.confirmPasswordValidator
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmPasswordValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})


export class ForgotPasswordComponent implements OnInit {
    error: string;
    isForgotPassword: boolean;
    forgotPasswordForm: FormGroup;
    isForgotPasswordFormSubmitted: boolean;
    isResetPassword: boolean;
    resetPasswordForm: FormGroup;
    otpForm: FormGroup;
    phone: any;
    otpIdx = 0;
    showOtp: boolean;
    route: any;
    token: any;
    guestUser = false;
    confirmPasswordFieldTextType = false;
    newPasswordFieldTextType = false;
    isFormSubmitted: boolean;
    username: string;
    isAppList: boolean;
    applist: any = [];
    isMessage = true;

    @ViewChild('otpFirst', { static: false }) otpFirst: ElementRef;
    @ViewChild('otpSecond', { static: false }) otpSecond: ElementRef;
    @ViewChild('otpThird', { static: false }) otpThird: ElementRef;
    @ViewChild('otpFourth', { static: false }) otpFourth: ElementRef;
    @ViewChild('otpFifth', { static: false }) otpFifth: ElementRef;
    @ViewChild('otpSixth', { static: false }) otpSixth: ElementRef;

    constructor(
        private spinner: NgxSpinnerService,
        private publicService: PublicService,
        private formBuilder: FormBuilder,
        private userServive: UserAuthService,
        private router: Router,
        private themeHelper: ThemeHelpersService,
        private toastr: ToastrService
    ) {
        this.forgotPasswordForm = this.formBuilder.group({
            username: []
        });
        this.themeHelper.listen().subscribe(data => {
            if (data[0] === 'redirect:reset') {
                this.isForgotPassword = false;
                this.route = data[1]['route'];
                this.token = data[1]['token'];
                this.forgotPasswordForm.controls['username'].setValue(data[1].username);
                console.log(this.forgotPasswordForm)
                this.userServive.saveToken(data[1]['token']);
                this.isResetPassword = true;
                this.isMessage = false;
                this.spinner.hide();
            }
        });
        this.createOTP();
        this.isForgotPassword = true;
        this.createConfirmPassword();
    }

    ngOnInit(): void {
    }
    focusOnLabel(selector) {
        document.getElementById(selector).focus();
    }


    submitForgotpassword() {
        this.isForgotPasswordFormSubmitted = true;
        if (this.forgotPasswordForm.invalid) {
            this.error = 'username can not be blank.';
            return;
        } else {
            this.publicService.forgotPassword(this.forgotPasswordForm.get('username').value).subscribe((response: any) => {
                //  setTimeout(() => { this.spinner.hide(); }, 2000);
                this.isForgotPassword = false;
                this.error = '';
                this.spinner.hide();
                let mobileNumber = response?.recipientAddress;

                mobileNumber = mobileNumber.substring(2);
                console.log(mobileNumber);

                this.phone = mobileNumber;

                this.showOtp = true;
                //  this.isLoginOtpSent = true;

            }, (error) => {
                //   this.spinner.hide();
                // console.log("error",error.error.message);
                this.error = error.error.message;
            });
        }
    }
    autoMoveOtp(nativeElemName, clearCurrent, idx) {

        if (this[clearCurrent].nativeElement.value.length == 0) {
            return;
        }

        this.otpIdx = idx;
        this[nativeElemName].nativeElement.focus();
    }


    checkFocus(clearCurrent, idx) {
        if (idx < this.otpIdx) {
            //this[clearCurrent].nativeElement.value = '';
        }
        this.otpIdx = idx;
    }
    clearVal(clearCurrent, idx) {
        this[clearCurrent].nativeElement.value = '';
    }

    resendOTP() {

        this.otpForm.reset();
        this.spinner.show();
        this.publicService.sendOTP(this.forgotPasswordForm.get('username').value).subscribe(
            (result) => {
                this.spinner.hide();
                // this.phone = this.user.username;
            },
            (error) => {
                this.spinner.hide();
                this.error = "Invalid mobile number";
            }
        );

    }

    validateOtp() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
        }, 3000);
        const sendOtp = {
            password: Object.values(this.otpForm.value).join(''),
            username: this.forgotPasswordForm.get('username').value
        }

        this.publicService.validateOTP(sendOtp).subscribe(
            (result: any) => {
                this.showOtp = false;
                this.error = '';
                this.route = result['route'];
                this.token = result['token'];
                this.spinner.hide();

                //  this.guestUser = true;
                this.userServive.saveToken(result['token']);
                this.isResetPassword = true;
                this.username = result.username;

            }, err => {
                console.log("error", err);
                this.error = err.error.message;
                console.log("error", err);
                this.spinner.hide();
            });
    }
    deleteOtp($event, clearCurrent, prevEl, idx) {
        if ($event.key === "Backspace") {
            if (this[clearCurrent].nativeElement.value.length > 0) {
                this[clearCurrent].nativeElement.value = '';
            } else {
                this[prevEl].nativeElement.value = '';
                this[prevEl].nativeElement.focus();
            }
        }

    }


    private createOTP() {
        this.otpForm = this.formBuilder.group({
            first: ['', Validators.required],
            second: ['', Validators.required],
            third: ['', Validators.required],
            fourth: ['', Validators.required],
            fifth: ['', Validators.required],
            sixth: ['', Validators.required]
        });
    }

    toggleConfirmPasswordFieldTextType() {
        this.confirmPasswordFieldTextType = !this.confirmPasswordFieldTextType;
    }

    toggleNewPasswordFieldTextType() {
        this.newPasswordFieldTextType = !this.newPasswordFieldTextType;
    }

    private createConfirmPassword() {
        this.resetPasswordForm = this.formBuilder.group(
            {
                newPassword: ['', Validators.required],
                confirmPassword: ['', Validators.required]
            },
            {
                validator: ConfirmPasswordValidator('newPassword', 'confirmPassword')
            }
        );
    }

    submitConfirmPassword() {
        this.isFormSubmitted = true;
        if (this.resetPasswordForm.invalid) {
            return;
        } else {
            window.sessionStorage.removeItem('token');
            const changePasswordObj = {
                username: this.forgotPasswordForm.get('username').value,
                password: this.resetPasswordForm.get('newPassword').value
            }
            this.spinner.show();
            this.publicService.changePassword(changePasswordObj).subscribe((response: any) => {
                this.spinner.hide();
                this.toastr.success('Request has been submitted successfully.','Success');
            }, err => {
                this.spinner.hide();
            });
        }
    }

    redirectToLogin() {
        this.router.navigate(['/login']);
    }
}
